import cashInIcon from '../assets/resources/icons/mwIcons/cash_in.png';
import cashOutIcon from '../assets/resources/icons/mwIcons/cash-out.png';
import profileIcon from '../assets/resources/icons/mwIcons/profile.png';
import securityIcon from '../assets/resources/icons/mwIcons/security.png';
import dashboardIcon from '../assets/resources/icons/mwIcons/dashboard.png';
import teamManagementIcon from '../assets/resources/icons/mwIcons/team-mgmt.png';
import teamBettingIcon from '../assets/resources/icons/mwIcons/team-betting.png';
import transRecords from '../assets/resources/icons/mwIcons/transaction-records.png';
import commissionReport from '../assets/resources/icons/mwIcons/commission_report.webp';

export const backOffice = [
  { title: "Dashboard", icon: dashboardIcon},
  { title: "Team Management", icon: teamManagementIcon },
  { title: "Team Betting", icon: teamBettingIcon },
  //{ title: "Dividends", icon: teammanagement },
  { title: "Commission Report", icon: commissionReport},
  { title: "Transaction Records", icon: transRecords },
  { title: "Cash-In Request", icon: cashInIcon },
  { title: "Cash-Out Request", icon: cashOutIcon },
];


export const userPanel = [
  { title: "Profile", icon: profileIcon },
  { title: "Security", icon: securityIcon },
];

export const faqItems = [
  {
    key: 1,
    title: "What is an online casino?", 
    label: "An online casino is a virtual platform where you can play various casino games, such as slots, table games, and card games, over the internet."
  },
  {
    key: 2,
    title: "Is it legal to play at your online casino?",
    label: "Yes, our online casino operates within the legal framework of the jurisdictions it serves. Please check your local laws to ensure online gambling is permitted in your area."
  },
  {
    key: 3,
    title: "How do I register an account?",
    label: `To register an account, simply click on the "Register" button on our website, and follow the step-by-step registration process.`
  },
  {
    key: 4,
    title: "How can I deposit money into my casino account?",
    label: "You can deposit funds into your account using various payment methods, including credit/debit cards, e-wallets, bank transfers, and other options."
  },
  {
    key: 5,
    title: "Is my financial information safe and secure?",
    label: "Yes, we use advanced encryption technology to protect your financial information and ensure your transactions are secure."
  },
  {
    key: 6,
    title: "Are the games fair?",
    label: "All games at our online casino are tested and certified for fairness by independent auditors. We use a Random Number Generator (RNG) to ensure random outcomes."
  },
  {
    key: 7,
    title: "Can I withdraw my winnings at any time?",
    label: "Yes, you can request a withdrawal of your winnings at any time."
  },
  {
    key: 8,
    title: "How long does it take to process withdrawals?",
    label: "Withdrawal processing times may vary depending on the chosen payment method. Kindly contact the Customer Support for more assistance regarding this step."
  },
];

export const termsItems = [
  {
    title: "1. Acceptance of Terms",
    label: `By accessing and using MoneyWin (the "Website" or "Service"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you are not permitted to use our service.`,
  },
  {
    title: "2. Eligibility",
    label: "You must be at least the legal gambling age in your jurisdiction to use our services. You are responsible for complying with the laws and regulations in your jurisdiction regarding online gambling.",
  },
  {
    title: "3. Registration and Accounts",
    label: "You agree to provide accurate and up-to-date information during the registration process. You are responsible for maintaining the confidentiality of your account login information.",
  },
  {
    title: "4. Deposits and Withdrawals",
    label: "Deposits into your account are subject to minimum and maximum limits. Withdrawals are subject to processing times and may require verification. Currency conversion rates may apply for deposits and withdrawals.",
  },
  {
    title: "5. Wagering and Betting",
    label: "You acknowledge that you may lose the funds you wager. All bets are final and cannot be reversed.",
  },
  {
    title: "6. Bonuses and Promotions",
    label: "Bonuses and promotions are subject to specific terms and wagering requirements. Failure to meet these requirements may result in the forfeiture of bonus funds.",
  },
  {
    title: "7. Responsible Gambling",
    label: "We promote responsible gambling and offer tools to help you control your gambling activity. You can set limits on deposits, bets, and losses, or self-exclude if needed.",
  },
  {
    title: "8. Fair Play and Cheating",
    label: "We have strict anti-cheating measures in place, and any form of cheating is strictly prohibited. The use of automated software, bots, or any form of artificial intelligence is not allowed.",
  },
  {
    title: "9. Disputes and Complaints",
    label: "If you have a dispute or complaint, please contact our customer support team for resolution. If a dispute arises, our decision will be final.",
  },
  {
    title: "10. Termination of Account",
    label: "We reserve the right to terminate or suspend your account at our discretion. Funds in your account may be returned to you subject to any applicable fees and legal requirements.",
  },
  {
    title: "11. Changes to Terms and Conditions",
    label: "We may update these terms and conditions at any time. It is your responsibility to review these terms regularly.",
  },
  {
    title: "12. Limitation of Liability",
    label: "MoneyWin is not responsible for any loss or damage resulting from the use of our service. We are not liable for any third-party content or services linked from our website.",
  },
  {
    title: "13. Contact Information",
    label: "If you have questions or concerns, please contact us through the Customer Support/Service.",
  }
];

export const gamesItems = [
  {
    key: 1,
    title: "Slot",
    label: "Online slots are digital versions of traditional slot machines found in casinos. These games involve spinning virtual reels with various symbols, and players win based on the combinations that appear when the reels stop. The symbols, themes, and the number of reels and paylines can vary, adding diversity to the gaming experience. Online slots often feature bonus elements like free spins and multipliers, while some offer progressive jackpots that grow with each bet. The outcomes are determined by Random Number Generators (RNGs) to ensure fairness.",
  },
  {
    key: 2,
    title: "Casino",
    label: "These games typically include classics like blackjack, roulette, poker, and slot machines. Players can place bets, spin virtual reels, or participate in card games, aiming to win money or prizes.",
  },
  {
    key: 3,
    title: "Fishing",
    label: "In the context of online casinos, Fishing is a digital adaptation that blends the excitement of shooting arcade-style gameplay with the thrill of fishing. In these games, players use virtual cannons or guns to shoot at a variety of sea creatures swimming across the screen. Each target hit can result in different prizes or rewards, adding an element of skill and strategy to the traditional casino experience. These games often feature vibrant underwater graphics, diverse marine life, and a points-based system where players aim to accumulate the highest score or win monetary rewards based on their shooting accuracy. Shooting fishing games provide a dynamic and visually engaging twist to the typical casino game lineup, appealing to those seeking a more interactive and action-packed gaming experience.",
  },
  {
    key: 4,
    title: "Poker",
    label: "Online casino poker offers a digital platform for players to enjoy the classic card game from the comfort of their homes. Variants such as Texas Hold'em, Omaha, and Stud poker are commonly featured, each with its unique set of rules and strategies. Players can join virtual tables, interact with opponents through chat functions, and engage in thrilling rounds of betting, bluffing, and strategic decision-making.",
  },
  {
    key: 5,
    title: "Table",
    label: "able games in online casinos encompass a diverse array of classic and popular games traditionally found in brick-and-mortar casinos. These include staples like blackjack, roulette, baccarat, and craps. With the advent of online gaming, players can now enjoy these table games virtually, replicating the authentic casino experience through digital interfaces. These games often feature realistic graphics, user-friendly interfaces, and various betting options to cater to different preferences. Online table games maintain the core rules and strategies of their land-based counterparts, allowing players to engage in strategic gameplay and compete against computer algorithms or other online players.",
  },
];
