import { Card, Table, Button, Modal, Input, Spin, Select, message as floatingMessage } from "antd";
import React, { useEffect, useState } from "react";
import { getTransactionRequests, getUserInfo,  processCashinRequest} from "../../../services/moneywin/moneywin-api";
import { showTimedMessage, showTimedMessageWithAction, formatDate, formatNumberWithCommas } from "../../../services/util/utilities";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/auth-slice';

const CashinRequest = () => {

  const [message, messageHolder] = Modal.useModal();
  const [cashinRequest, setCashinRequests] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [mode, setMode] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [selectedPercentage, setSelectedPercentage] = useState(null);
  const [bonus, setBonus] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const isMobileBreakpoint = 768;
  const isMobile = window.innerWidth <= isMobileBreakpoint;
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserInfo(token);
      if (response.user_id) {
        const requests = await getTransactionRequests(response.user_id, 'cashin', 'pending');
        if (requests && requests.transaction_requests && requests.transaction_requests.length > 0) {
          setCashinRequests(requests.transaction_requests);
        }else{
          setCashinRequests([]);
        }
       
      }else if(response.timed_out || response.request_failed){
        floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
      }else if(response.error){
        showTimedMessageWithAction(message, "Session Expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
        async () => {
          dispatch(logout());
        });  
      }

      setTableLoading(false);
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);
  
    return () => {
      clearInterval(interval);
    };

}, );

const handleOpenDialog = async (record, mode) => {
  setSelectedRecord(record);
  setVisible(true);
  setMode(mode);
};

const handleCloseDialog = () => {
  setVisible(false);
  setSelectedPercentage(null);
  setRemarks(null);
};

const handlePercentageChange = (value) => {
  setSelectedPercentage(parseFloat(value));
  setBonus(parseFloat(value));
};
const handleConfirm = async (transaction_id, reference) => {
  try {
    setConfirmLoading(true);
    const action = mode === "Accept" ? "completed" : "declined";
    const response = await getUserInfo(token);
    
   
    if (response && response.user_id) {
      const cashin = await processCashinRequest(
        response.user_id,
        transaction_id,
        action,
        reference,
        bonus,
        remarks
      );
      if (cashin.success) {
        handleCloseDialog();
        showTimedMessage(message, "Success", `Cashin ${action}`, "OK", 5, true);
      }else{
        showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
      }
    }else{
      showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
    }
  } catch (error) {
    showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
  }finally{
    setConfirmLoading(false);
  }
};


const columns = [
  {
    title: "Date Request",
    dataIndex: "created_at",
    key: "created_at",
    width: 260,
    align: 'center',
    render: (text) => {
      return <span>{formatDate(text)}</span>
    },
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "House",
    dataIndex: "house_username",
    key: "house_username",
    render: (record) => (record == null ? "-" : record),
  },
  {
    title: "Direct Link",
    dataIndex: "direct_link_username",
    key: "direct_link_username",
    render: (record) => (record == null ? "-" : record),
  },
  {
    title: "Payment Method",
    dataIndex: "payment_channel",
    key: "payment_channel",
  },
  {
    title: "Reference",
    dataIndex: "transaction_reference",
    key: "transaction_reference",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (record) => (
      <div>
        {record <= 0 ? (
          <span>-</span>
        ) : (
          <span>{formatNumberWithCommas(record)}</span>
        )}
      </div>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (type, record) => {
      let statusColor;
      if(record.status === "pending"){
        statusColor = 'text-yellow-300';
      }
      if(record.status === "completed"){
        statusColor = 'text-green-500';
      }
      if(record.status === "failed"){
        statusColor = 'text-red-500';
      }
      if(record.status === "cancelled"){
        statusColor = 'text-gray-400';
      }
      if(record.status === "declined"){
        statusColor = 'text-gray-400';
      }
      return (
        <span className={`${statusColor} font-semibold`}>{record.status.charAt(0).toUpperCase() + type.slice(1)}</span>
      )
    },
  },
  {
    title: "Action",
    key: "accept_request",
    align: "center",
    fixed: isMobile ? undefined : 'right',
    width: "150px",
    render: (text, record) => (
      <div style={{ width: "120px", textAlign: "center", margin: "0 auto" }}>
        <Button
          type="primary"
          className="mb-2" 
          onClick={() => handleOpenDialog(record, 'Accept')}
        >
          Accept
        </Button>
        <Button  
          onClick={() => handleOpenDialog(record, 'Decline')}
        >
          Decline
        </Button>
      
      </div>
      
    ),
  },
];
  
return (
    <div className="bg-primary h-full w-full p-5">
      {messageHolder}
      <Modal
          title={mode + " cashin request?"}
          open={visible}
          onCancel={handleCloseDialog}
          footer={null}
          closable={!confirmLoading} // Disable closing while loading
        >
          {confirmLoading ? (
            <div className="flex justify-center items-center h-32">
              {/* Loading indicator */}
              <Spin size="large" />
            </div>
          ) : (
            <div className="flex flex-col">
              <label className="pb-2">Username: {selectedRecord && selectedRecord.username}</label>
              <label className="pb-2">Payment Method: {selectedRecord && selectedRecord.payment_channel}</label>
              <label className="pb-2">Reference: {selectedRecord && selectedRecord.transaction_reference}</label>
              <label className="pb-5">Amount: {selectedRecord && selectedRecord.amount}</label>
              
              {mode === "Accept" ? (
                <Select
                  id="percentage"
                  className="py-3"
                  placeholder="Bonus chips amount"
                  defaultValue="Select Value"
                  value={selectedPercentage}
                  onChange={handlePercentageChange}
                >
                  {selectedRecord && (
                    <>
                      <option value={selectedRecord.amount.replace(/,/g, '') * 0.10}>10% ({selectedRecord.amount.replace(/,/g, '') * 0.10})</option>
                      <option value={selectedRecord.amount.replace(/,/g, '') * 0.20}>20% ({selectedRecord.amount.replace(/,/g, '') * 0.20})</option>
                      <option value={selectedRecord.amount.replace(/,/g, '') * 0.30}>30% ({selectedRecord.amount.replace(/,/g, '') * 0.30})</option>
                      <option value={selectedRecord.amount.replace(/,/g, '') * 0.40}>40% ({selectedRecord.amount.replace(/,/g, '') * 0.40})</option>
                      <option value={selectedRecord.amount.replace(/,/g, '') * 0.50}>50% ({selectedRecord.amount.replace(/,/g, '') * 0.50})</option>
                    </>
                  )}
                </Select>
              ) : null}

              <Input
                className="py-3 mb-2"
                placeholder="Enter remarks..."
                onChange={(e) => setRemarks(e.target.value)} // Update the 'remarks' state
                value={remarks} // Reflect the 'remarks' state value
              />
              <div className="flex justify-end mt-3">
                <Button 
                  type="primary" 
                  style={{ backgroundColor: "black" }} 
                  onClick={() => handleConfirm(selectedRecord && selectedRecord.id, selectedRecord.transaction_reference)}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
      </Modal>
      <div className="shadow-md rounded-md">
        <Card title="Cash-In Request (Manual)" >
          <Table 
            bordered
            loading={tableLoading}
            className={` min-h-[230px] shadow-md rounded-md`}
            dataSource={cashinRequest} 
            columns={columns} rowKey={"key"}
            responsive
            scroll={{
              x: 1700,
              y: "100%",
            }}
          />
        </Card>
      </div>
      
    </div>
  );
};

export default CashinRequest;