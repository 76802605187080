import Icon from "@ant-design/icons";

export const MenuIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.54 8.31001C18.1924 8.31001 18.8181 8.05084 19.2795 7.5895C19.7408 7.12816 20 6.50245 20 5.85001C20 5.19758 19.7408 4.57187 19.2795 4.11053C18.8181 3.64919 18.1924 3.39001 17.54 3.39001C16.8876 3.39001 16.2619 3.64919 15.8005 4.11053C15.3392 4.57187 15.08 5.19758 15.08 5.85001C15.08 6.50245 15.3392 7.12816 15.8005 7.5895C16.2619 8.05084 16.8876 8.31001 17.54 8.31001ZM6.46 8.31001C7.11243 8.31001 7.73814 8.05084 8.19948 7.5895C8.66082 7.12816 8.92 6.50245 8.92 5.85001C8.92 5.19758 8.66082 4.57187 8.19948 4.11053C7.73814 3.64919 7.11243 3.39001 6.46 3.39001C5.80757 3.39001 5.18186 3.64919 4.72052 4.11053C4.25918 4.57187 4 5.19758 4 5.85001C4 6.50245 4.25918 7.12816 4.72052 7.5895C5.18186 8.05084 5.80757 8.31001 6.46 8.31001ZM17.54 20.61C17.8631 20.61 18.1829 20.5464 18.4814 20.4228C18.7799 20.2991 19.051 20.1179 19.2795 19.8895C19.5079 19.6611 19.6891 19.3899 19.8127 19.0914C19.9364 18.793 20 18.4731 20 18.15C20 17.827 19.9364 17.5071 19.8127 17.2086C19.6891 16.9102 19.5079 16.639 19.2795 16.4105C19.051 16.1821 18.7799 16.0009 18.4814 15.8773C18.1829 15.7536 17.8631 15.69 17.54 15.69C16.8876 15.69 16.2619 15.9492 15.8005 16.4105C15.3392 16.8719 15.08 17.4976 15.08 18.15C15.08 18.8024 15.3392 19.4282 15.8005 19.8895C16.2619 20.3508 16.8876 20.61 17.54 20.61ZM6.46 20.61C6.78305 20.61 7.10294 20.5464 7.4014 20.4228C7.69986 20.2991 7.97105 20.1179 8.19948 19.8895C8.42792 19.6611 8.60912 19.3899 8.73274 19.0914C8.85637 18.793 8.92 18.4731 8.92 18.15C8.92 17.827 8.85637 17.5071 8.73274 17.2086C8.60912 16.9102 8.42792 16.639 8.19948 16.4105C7.97105 16.1821 7.69986 16.0009 7.4014 15.8773C7.10294 15.7536 6.78305 15.69 6.46 15.69C5.80757 15.69 5.18186 15.9492 4.72052 16.4105C4.25918 16.8719 4 17.4976 4 18.15C4 18.8024 4.25918 19.4282 4.72052 19.8895C5.18186 20.3508 5.80757 20.61 6.46 20.61Z"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const LogoutIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.9 7.55999C9.21 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24 20.08 8.91 16.54M15 12H3.62M5.85 8.64999L2.5 12L5.85 15.35"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const AddBalanceIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 12H16M12 16V8M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);

export const RechargeIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5365 12.4208C16.1515 12.7967 15.9315 13.3375 15.9865 13.915C16.069 14.905 16.9765 15.6292 17.9665 15.6292H19.7082V16.72C19.7082 18.6175 18.159 20.1667 16.2615 20.1667H6.994C7.27817 19.9283 7.52567 19.635 7.71817 19.305C8.05734 18.755 8.24984 18.1042 8.24984 17.4167C8.24977 16.7259 8.05453 16.0492 7.68662 15.4646C7.31871 14.88 6.7931 14.4112 6.17037 14.1123C5.54764 13.8134 4.85312 13.6965 4.16686 13.7751C3.48059 13.8537 2.8305 14.1247 2.2915 14.5567V10.5508C2.2915 8.65332 3.84067 7.10416 5.73817 7.10416H16.2615C18.159 7.10416 19.7082 8.65332 19.7082 10.5508V11.8708H17.8565C17.3432 11.8708 16.8757 12.0725 16.5365 12.4208Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.2915 11.3758V7.18667C2.2915 6.09584 2.96067 5.12417 3.97817 4.73917L11.2565 1.98917C11.5202 1.88979 11.8041 1.85589 12.0838 1.89038C12.3635 1.92487 12.6307 2.02671 12.8623 2.18717C13.094 2.34763 13.2833 2.56191 13.4139 2.81163C13.5446 3.06134 13.6126 3.33902 13.6123 3.62084V7.10417M6.4165 11H12.8332M20.6789 12.8058V14.6942C20.6789 15.1983 20.2756 15.6108 19.7623 15.6292H17.9656C16.9756 15.6292 16.0681 14.905 15.9856 13.915C15.9306 13.3375 16.1506 12.7967 16.5356 12.4208C16.8748 12.0725 17.3423 11.8708 17.8556 11.8708H19.7623C20.2756 11.8892 20.6789 12.3017 20.6789 12.8058Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.95084 17.3983H3.21918M4.58318 16.06V18.8008M8.24984 17.4167C8.24984 18.1042 8.05734 18.755 7.71818 19.305C7.52568 19.635 7.27818 19.9283 6.99401 20.1667C6.35234 20.7442 5.50901 21.0833 4.58318 21.0833C3.95119 21.0846 3.32976 20.9213 2.78006 20.6095C2.23036 20.2977 1.77133 19.8481 1.44818 19.305C1.09924 18.737 0.915157 18.0832 0.916511 17.4167C0.916511 16.2617 1.44818 15.2258 2.29151 14.5567C2.83051 14.1246 3.4806 13.8537 4.16686 13.7751C4.85313 13.6965 5.54765 13.8134 6.17038 14.1123C6.79311 14.4112 7.31872 14.88 7.68662 15.4646C8.05453 16.0492 8.24977 16.7259 8.24984 17.4167Z"
          stroke="white"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);
