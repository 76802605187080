import { useEffect , useRef, useState } from "react";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Card, Input, Select, Table, Button, Modal, Spin, Switch, DatePicker, message as floatingMessage, Checkbox } from "antd";
import { showConstrainedMessages, showTimedMessage, showTimedMessageWithAction, formatNumberWithCommas } from "../../../services/util/utilities";
import { Page, Text, View, Document, StyleSheet, pdf, BlobProvider } from '@react-pdf/renderer';
import moment from 'moment';
import { useLocation } from "react-router-dom";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';
import { getCommissionReportV2, getManagedUsers, getReport, getUpline, getUserInfo } from "../../../services/moneywin/moneywin-api";


const CommissionReport = () => {

    const location = useLocation();

    const [user_id, setUserId] = useState(location.state?.user_id);
    const [role_id, setRoleId] = useState(location.state?.role_id);

    const [managedUsers, setManagedUsers] = useState([]);
    const [managedUsers2, setManagedUsers2] = useState([]);
    const [managedUsers3, setManagedUsers3] = useState([])

    const [managedPlayers, setManagedPlayers] = useState([]);
    const [managedPlayers2, setManagedPlayers2] = useState([]);
    const [managedPlayers3, setManagedPlayers3] = useState([]);
    const [managedPlayers4, setManagedPlayers4] = useState([]);
    
    const [playerTotalGGR, setPlayerTotalGGR] = useState('');

    const [hasUsers, setHasUsers] = useState(false);
    const [hasUsers2, setHasUsers2] = useState(false);
  
    const [hasPlayers, setHasPlayers] = useState(false);
    const [hasPlayers2, setHasPlayers2] = useState(false);
    const [hasPlayers3, setHasPlayers3] = useState(false);
    
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [expandedRowKeys2, setExpandedRowKeys2] = useState([]);
    const [expandedRowKeys3, setExpandedRowKeys3] = useState([]);

    const [cardTitle1, setCardTitle1] = useState(null);
    const [cardTitle2, setCardTitle2] = useState(null);

    const [selectedRecord, setSelectedRecord] = useState(null);

    const [tableLoading, setTableLoading] = useState(true);

    const [searchInput, setSearchInput] = useState('');
    const [searchMode, setSearchMode] =useState(false);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [message, messageHolder] = Modal.useModal();

    const [teamBettingChecked, setTeamBettingChecked] = useState(true);
    const [dashboardChecked, setDashboardChecked] = useState(true);
    const [commissionsChecked, setCommissionsChecked] = useState(true);

    const [generateLoading, setGenerateLoading] = useState(false);

    const [generateReportModal, setGenerateReportModal] = useState(false);

    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    useEffect(() => {

        const fetchData = async () => {

            const response = await getUserInfo(token);
            
            if (response.user_id && response.role_id) {
      
                if(searchMode){
                    return
                };
        
                setUserId(response.user_id);
                setRoleId(response.role_id);
                
                let usersData;
                    
                if (fromDate && toDate) {
                    usersData = await getCommissionReportV2(response.user_id, response.role_id, fromDate, toDate);
                } else {
                    usersData = await getCommissionReportV2(response.user_id, response.role_id);
                }

                if (usersData) {
                    setManagedUsers(usersData.users);
                    setManagedPlayers(usersData.players);
                    const totalGGR = usersData.players.reduce((sum, player) => sum + parseFloat(player.commission_gained || '0'), 0);
                    setPlayerTotalGGR(totalGGR);
                }
            }else if(response.timed_out || response.request_failed){

                floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
           
            }else if(response.error){

                showTimedMessageWithAction(message, "Session expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
                async () => {
                    dispatch(logout());
                });  
            }
      
            setTableLoading(false);
        };

        fetchData();

        const interval = setInterval(fetchData, 10000);

        return () => {
        clearInterval(interval);
        };
      
    }, [user_id, role_id, searchMode, fromDate, toDate]);

    const handleRowExpand = async (expanded, record) => {
        let newExpandedRowKeys = [];
        if (expanded) {
            newExpandedRowKeys = [record.user_id];
            setExpandedRowKeys([]); 
            setExpandedRowKeys2([]); 
        }
      
        setExpandedRowKeys(newExpandedRowKeys);
      
        if (((searchMode && (hasPlayers === false && hasUsers === false)) || !searchMode) && expanded) {
            await getUsers(record.role_id, record.user_id, setManagedUsers2, setManagedPlayers2);
        }
      
        let cardTitle = "";
        if (record.role_id < 4) {
            cardTitle = "Agents";
        } else if (record.role_id === 4) {
            cardTitle = "Players";
        }
      
      
        setCardTitle1(cardTitle);
      };
      
    const handleSecondRowExpand = async (expanded, record) => {
        let newExpandedRowKeys2 = [];
        if (expanded) {
            newExpandedRowKeys2 = [record.user_id];
            setExpandedRowKeys2([]); 
        }
      
        setExpandedRowKeys2(newExpandedRowKeys2);
      
        if (((searchMode && (hasPlayers2 === false && hasUsers2 === false)) || !searchMode) && expanded) {
            await getUsers(record.role_id, record.user_id, setManagedUsers3, setManagedPlayers3);
        }
      
        let cardTitle = "";

        if (record.role_id < 4) {
            cardTitle = "Agents";
        } else if (record.role_id === 4) {
            cardTitle = "Players";
        }
      
        setCardTitle2(cardTitle);
    };
  
    const handleThirdRowExpand = async (expanded, record) => {
        let newExpandedRowKeys3 = [];

        if (expanded) {
            newExpandedRowKeys3 = [record.user_id];
            setExpandedRowKeys3([]); 
        }
      
        setExpandedRowKeys3(newExpandedRowKeys3);
      
        if (((searchMode && (hasPlayers3 === false)) || !searchMode) && expanded) {
            await getUsers(record.role_id, record.user_id, null, setManagedPlayers4);
        }
    };

    const getUsers = async (role_id, user_id, setManagedUsers, setManagedPlayers) => {
        if (user_id && role_id) {
            if(setManagedUsers != null){
                setManagedUsers(null); 
            }
            
            setManagedPlayers([]);
    
            let usersData; 
                
            if (fromDate && toDate) {
                usersData = await getManagedUsers(role_id, user_id, fromDate, toDate);
            } else {
                usersData = await getManagedUsers(role_id, user_id);
            }

            if (usersData) {
                if(role_id === 4){
                    if(setManagedUsers != null){
                        setManagedUsers(usersData.players);
                    }
                }else{
                    if(setManagedUsers != null){
                        setManagedUsers(usersData.users);
                    }
                    setManagedPlayers(usersData.players);
                }
            }
        }
    };

    const handleSearchEnter = async () => {
    
        const username = searchInput.trim().toLowerCase(); 
        if(username !== ''){
            setTableLoading(true);
            setSearchMode(true);
            setManagedUsers(null);
            setManagedUsers2(null);
            setManagedUsers3(null);
            setManagedPlayers(null);
            setManagedPlayers2(null);
            setManagedPlayers3(null);
            setManagedPlayers4(null);
            setHasPlayers(false);
            setHasPlayers2(false);
            setHasPlayers3(false);
            setHasUsers(false);
            setHasUsers2(false);
          
            const response = await getUserInfo(token);
        
            if (response && response.user_id) {
    
                const upline = await getUpline(username, response.user_id);
                
                if(upline && upline.current_user){
                    const currentUser = upline.current_user[0];
        
                    if (upline.upline.length === 0) {
                        if(currentUser.role_id === 5){
                            setManagedPlayers(upline.current_user);
                        }else{
                            setManagedUsers(upline.current_user)
                        }
                    }else if (upline.upline.length === 1) {
                        if(currentUser.role_id === 5){
                            setManagedUsers(upline.upline);
                            setManagedPlayers2(upline.current_user);
                            setHasPlayers(true);
                        }else{
                            setManagedUsers(upline.upline);
                            setManagedUsers2(upline.current_user); 
                            setHasUsers(true);
                        }
                    }else if (upline.upline.length === 2) {
                        if(currentUser.role_id === 5){
                            setManagedUsers([upline.upline[0]]);
                            setManagedUsers2([upline.upline[1]]);
                            setManagedPlayers3(upline.current_user);
                            setHasUsers(true);
                            setHasPlayers2(true);
                        }else if(currentUser.role_id === 4){
                            setManagedUsers([upline.upline[0]]);
                            setManagedUsers2([upline.upline[1]]);
                            setManagedUsers3(upline.current_user);
                            setHasUsers(true);
                            setHasUsers2(true);
                        }
                        
                    }else if (upline.upline.length === 3) {
                        setManagedUsers([upline.upline[0]]);
                        setManagedUsers2([upline.upline[1]]);
                        setManagedUsers3([upline.upline[2]]);
                        setManagedPlayers4(upline.current_user);
                        setHasUsers(true);
                        setHasUsers2(true);
                        setHasPlayers3(true);
                    }
                }
                setTableLoading(false);
            }
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value); // Update the searchInput state
        if(event.target.value === ''){
            setSearchMode(false);
        } 
      };
    
    const handleClearSearch = () => {
        setSearchMode(false);
        setSearchInput('');
    };
    
    
    const handleDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            const startDate = moment(dates[0].toDate()).format('YYYY-MM-DD HH:mm:ss');
            const endDate = moment(dates[1].toDate()).format('YYYY-MM-DD HH:mm:ss');
        
            setFromDate(startDate);
            setToDate(endDate);
        }else{
            setFromDate(null);
            setToDate(null);
        }
    };

    const handleGenerateReport = async (record) => {
        setGenerateLoading(true);
        
        try{
            const response = await getUserInfo(token);
            
            if (response.user_id) {
                const report = await getReport(selectedRecord.user_id, fromDate, toDate);
                // console.log(report);
                // console.log(report.players);
                if(report){
                    const from = fromDate ? fromDate.substring(0, 10) : null;
                    const to = toDate ? toDate.substring(0, 10) : null;
                    const blob = await pdf(<MyDocument report={report} />).toBlob();
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    if(from && to){
                        link.download = `${from} - ${to}`;
                    }else{
                        link.download = 'all-records.pdf';
                    }
                    
                    link.click();
                }
            }else{
                floatingMessage.error({ content: "Unable to generate report. Please make sure there is a data to generate." , key: 'custom_message' });
            }
        }catch($e){
            floatingMessage.error({ content: `Something went wrong, please try again later. ${$e}` , key: 'custom_message' });
        }finally{
            setGenerateLoading(false);
        }
    };

    const handleOpenGenerateReport = async (record) => {
        setSelectedRecord(record);
        setGenerateReportModal(true);
    };

    const handleCloseGenerateReportModal = async (record) => {
        setGenerateReportModal(false);
        setSelectedRecord(null);
    };

    const handleTeamBettingChange = (event) => {
        setTeamBettingChecked(event.target.checked);
    };

    const handleDashboardChange = (event) => {
        setDashboardChecked(event.target.checked);
    };

    const handleCommissionsChange = (event) => {
        setCommissionsChecked(event.target.checked);
    };

    const MyDocument = ({ report }) => (
        <Document>
            <Page size={[841.89, 595.28]} style={styles.page}>
                <View>
                    {fromDate && toDate && 
                        <View style={styles.topDateContainer}>
                            <Text style={styles.topDateText}>{fromDate} to {toDate}</Text>
                        </View>
                    }
                    {teamBettingChecked && 
                        <>
                            <Text style={styles.title}>TEAM BETTING</Text>
                            <View style={styles.table}>
                                <View style={styles.tableHeader}>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Username</Text>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Direct Link</Text>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Cashed-In</Text>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Cashed-Out</Text>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Bets Placed</Text>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Total Winnings</Text>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Bets Lost</Text>
                                    <Text style={[styles.columnHeader, { width: "12.5%" }]}>Running Bal</Text>
                                </View>
                                {report.players.map((row, rowIndex) => (
                                    <View style={styles.tableRow} key={rowIndex}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{row.username}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{row.direct_link}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{formatNumberWithCommas(row.total_cashin)}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{formatNumberWithCommas(row.total_cashout)}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{formatNumberWithCommas(row.total_stake)}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{formatNumberWithCommas(row.total_payout)}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{formatNumberWithCommas(row.total_lost)}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{formatNumberWithCommas(row.running_balance)}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </>
                    }
                    
                    {dashboardChecked && 
                        <View style={styles.bottomTextContainer}>
                            <Text style={styles.title}>SUMMARY</Text>
                            <View style={[styles.bottomTextRow, styles.grayRow]}>
                                <Text style={styles.bottomTextKey}>Total Deposits:</Text>
                                <Text style={styles.bottomTextValue}>{formatNumberWithCommas(report.total_cashin_sum)}</Text>
                            </View>
                            <View style={styles.bottomTextRow}>
                                <Text style={styles.bottomTextKey}>Total Withdrawals:</Text>
                                <Text style={styles.bottomTextValue}>{formatNumberWithCommas(report.total_cashout_sum)}</Text>
                            </View>
                            <View style={[styles.bottomTextRow, styles.grayRow]}>
                                <Text style={styles.bottomTextKey}>Total Valid Bets:</Text>
                                <Text style={styles.bottomTextValue}>{formatNumberWithCommas(report.total_stake_sum)}</Text>
                            </View>
                            <View style={styles.bottomTextRow}>
                                <Text style={styles.bottomTextKey}>Total Winnings:</Text>
                                <Text style={styles.bottomTextValue}>{formatNumberWithCommas(report.total_payout_sum)}</Text>
                            </View>
                            <View style={[styles.bottomTextRow, styles.grayRow]}>
                                <Text style={styles.bottomTextKey}>Total GGR:</Text>
                                <Text style={styles.bottomTextValue}>{formatNumberWithCommas(report.total_ggr)}</Text>
                            </View>
                        </View>
                    }
                    
                    {commissionsChecked && 
                        <View style={styles.bottomTextContainer}>
                            <Text style={styles.title}>COMMISSION DISTRIBUTION</Text>
                            <View style={[styles.bottomTextRow, styles.grayRow]}>
                                <Text style={styles.bottomTextKey}>House Commission ({(parseFloat(report.house_percentage) * 100).toFixed(2)}%):</Text>
                                <Text style={styles.bottomTextValue}>{formatNumberWithCommas(report.house_commission)}</Text>
                            </View>
                            <View style={styles.bottomTextRow}>
                                <Text style={styles.bottomTextKey}>Admin Commission ({(parseFloat(report.admin_percentage) * 100).toFixed(2)}%):</Text>
                                <Text style={styles.bottomTextValue}>{formatNumberWithCommas(report.admin_commission)}</Text>
                            </View>
                        </View>
                    }
                </View>
            </Page>
        </Document>
    );

    const styles = StyleSheet.create({
        page: {
            padding: 30,
        },
        table: {
            display: "table",
            width: "auto",
        },
        title: {
            fontSize: 16,
            fontWeight: 'bold',
            marginBottom: 10,
        },
        tableHeader: {
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "black",
        },
        columnHeader: {
            fontSize: 11,
            fontWeight: 'bold',
            textAlign: 'center',
            padding: 3,
            borderStyle: "solid",
            borderWidth: 1,
        },
        tableRow: {
            flexDirection: "row",
        },
        tableCol: {
            width: "12.5%",
            borderStyle: "solid",
            borderWidth: 1,
        },
        tableCell: {
            fontSize: 10,
            textAlign: "center",
            padding: 5,
        },
        bottomTextContainer: {
            marginTop: 20,
        },
        bottomTextRow: {
            padding: 5,
            flexDirection: 'row',
            alignItems: 'center',
        },
        bottomTextKey: {
            width: 150, 
            marginRight: 10,
            textAlign: 'left',
            fontSize: 11,
        },
        bottomTextValue: {
            textAlign: 'right',
            flex: 1,
            fontSize: 11,
            fontWeight: 'bold',
        },
        grayRow: {
            backgroundColor: '#f0f0f0', // Light gray background color for even rows
        },
        topDateContainer: {
            marginTop: 5,
            marginBottom: 10,
        },
        topDateText: {
            fontSize: 10,
            padding: 5,
            flexDirection: 'row',
            textAlign: 'right',
        },
    });

    const houseColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "%",
            dataIndex: "agent_percentage",
            key: "agent_percentage",
            width: 80,
            render: (text, record) => (
                <div className="flex justify-between items-center">
                    <span>{text === "0%" ? "-" : text}</span>
                </div>
            ),
        },
        {
            title: "Total GGR",
            dataIndex: "total_ggr", 
            key: "total_ggr",
            render: (text) => {
                const isNegative = text < 0;
                return (
                    <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
        {
            title: "Commission Gained",
            dataIndex: "commission_gained", 
            key: "commission_gained",
            render: (text) => {
                const isNegative = text < 0;
                return (
                    <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
        {
            title: "Admin Commission",
            dataIndex: "direct_link_commission", 
            key: "direct_link_commission",
            render: (text) => {
                const isNegative = text < 0;
                return (
                    <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
        {
            title: "Total Players",
            dataIndex: "total_players", 
            key: "total_players",
        },
        {
            title: "Active Players",
            dataIndex: "active_players", 
            key: "active_players",
        },
        {
            title: "Cashed In",
            dataIndex: "cashed_in", 
            key: "cashed_in",
            render: (text) => {
                return (
                    <span>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
        {
            title: "Cashed Out",
            dataIndex: "cashed_out", 
            key: "cashed_out",
            render: (text) => {
                return (
                    <span>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
        {
            title: "",
            key: "accept_request",
            align: "center",
            width: "250px",
            render: (text, record) => {
                if(record.role_id === 2){
                    return (
                        <div style={{ width: "200px", textAlign: "center", margin: "0 auto" }}>
                            <Button 
                                type="primary" 
                                className="mb-2" 
                                style={{ minWidth: "200px", backgroundColor: "#305EDB" }}
                                onClick={() => handleOpenGenerateReport(record)}
                            >
                                Generate Report
                            </Button>
            
                            <Button 
                                disabled
                                type="primary" 
                                className="mb-2" 
                                style={{ minWidth: "200px", backgroundColor: "#EF4444" }}
                                //onClick={() => handleOpenChipsDialog(record, "chips")}
                            >
                                Reset Commissions
                            </Button>
                        </div>
                    );
                }
                return null;
            },
        },
    ];

    const agentColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "%",
            dataIndex: "agent_percentage",
            key: "agent_percentage",
            render: (text, record) => (
                <div className="flex justify-between items-center">
                    <span>{text === "0%" ? "-" : text}</span>
                </div>
            ),
        },
        {
            title: "Total GGR",
            dataIndex: "total_ggr", 
            key: "total_ggr",
            render: (text) => {
                const isNegative = text < 0;
                return (
                    <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
        {
            title: "Commission",
            dataIndex: "commission_gained", 
            key: "commission_gained",
            render: (text) => {
                const isNegative = text < 0;
                return (
                    <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
    ];

    const playerColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Total GGR",
            dataIndex: "commission_gained",
            key: "commission_gained",
            render: (text) => {
                const isNegative = text < 0;
                return (
                    <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                        {formatNumberWithCommas(text)}
                    </span>
                );
            }
        },
    ];

    return (
        <div className="bg-primary h-full w-full p-5">
            <Card
                title={
                    <div className="flex flex-col">
                        <span className="py-5">
                            Commission Report 
                        </span>
                        <div className="flex flex-col lg:flex-row lg:justify-between w-full py-3 gap-4 lg:items-center">
                            <div className="flex flex-col lg:flex-row gap-4">
                                <Input.Search
                                    id="searchInput"
                                    size="large"
                                    enterButton={
                                        <button
                                            className="border-none h-[40px] px-4 text-white rounded-lg bg-black text-sm font-semibold cursor-pointer hover:bg-[#2f2936] transition-all ease-in duration-150"
                                            style={{ color: "white", borderRadius: "0 5px 5px 0" }}
                                            onClick={handleSearchEnter}
                                        >
                                            <SearchOutlined style={{ color: "white" }} />
                                        </button>
                                    }
                                    suffix={
                                        searchMode ? (
                                            <button
                                                className="border-none h-[20px] w-[20px] rounded-full bg-red-500 text-white cursor-pointer ml-2 flex items-center justify-center"
                                                style={{ color: "white" }}
                                                onClick={handleClearSearch}
                                            >
                                                <CloseOutlined style={{ fontSize: "10px" }} />
                                            </button>
                                        ) : null
                                    }
                                    onChange={handleSearchInputChange}
                                    value={searchInput}
                                    onPressEnter={handleSearchEnter}
                                    onKeyDown={(e) => {
                                        if (e.key === "Escape") {
                                            handleClearSearch();
                                        }
                                    }}
                                />

                                <DatePicker.RangePicker
                                    className="lg:min-w-[200px] h-[40px]"
                                    format="YYYY-MM-DD HH:mm a"
                                    showTime
                                    use12Hours
                                    placeholder={['Date From', 'Date To']}
                                    onChange={handleDateRangeChange}
                                />
                            </div>
                        </div>
                    </div>
                }
            >

                <Modal
                    title="Generate Report"
                    open={generateReportModal}
                    onCancel={handleCloseGenerateReportModal}
                    footer={null}
                    onOpen={() => {}}
                    closable={!generateLoading}
                >
                    {generateLoading ? (
                        <div className="flex justify-center items-center h-32">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <div className="flex-col mt-5">
                            <Checkbox checked={teamBettingChecked} onChange={handleTeamBettingChange}>Team Betting</Checkbox>
                            <Checkbox checked={dashboardChecked} onChange={handleDashboardChange}>Dashboard</Checkbox>
                            <Checkbox checked={commissionsChecked} onChange={handleCommissionsChange}>Commissions</Checkbox>

                            <div className="flex justify-end mt-5">
                                <Button type="primary" style={{ backgroundColor: "black" }} onClick={() => handleGenerateReport()}>Generate</Button>
                            </div>
                        </div>
                    )}
                    
                </Modal>

                <div className="p-1 rounded-lg">
                    {messageHolder}
                    <Table
                        scroll={{
                            x: 1000,
                            y: "100%",
                        }}
                        bordered
                        dataSource={managedUsers}
                        loading={tableLoading}
                        className={`min-h-[230px] shadow-md rounded-md`}
                        columns={role_id === 4 ? playerColumns : houseColumns}
                        rowKey="user_id"
                        expandable={role_id === 4 ? null : {
                            expandedRowKeys,
                            onExpand: handleRowExpand,
                            expandedRowRender: (record) => ( 
                                <div className="flex flex-col gap-4 p-5 border-2 border-solid border-[#2AA364] rounded-lg">
                                    <span className="text-xl font-semibold text-[#2AA364]">{record.username}</span>
                                    <Card title={cardTitle1}>
                                        <Table
                                            bordered
                                            className="mb-4 min-h-[230px]"
                                            dataSource={managedUsers2}
                                            columns={record.role_id === 4 ? playerColumns : record.role_id === 2 ? agentColumns : houseColumns}
                                            rowKey="user_id"
                                            expandable={record.role_id === 4 ? null : {
                                                expandedRowKeys: expandedRowKeys2,
                                                onExpand: handleSecondRowExpand,
                                                expandedRowRender: (record) => (
                                                <div className="flex flex-col gap-4 p-5 border-solid border-[#305EDB] border-2 rounded-lg">
                                                    <span className="text-xl font-semibold text-[#305EDB]">{record.username}</span>
                                                    <Card title={cardTitle2}>
                                                        <Table
                                                            bordered
                                                            className="mb-4"
                                                            dataSource={managedUsers3}
                                                            columns={record.role_id === 4 ? playerColumns : record.role_id === 2 ? agentColumns : houseColumns}
                                                            rowKey="user_id"
                                                            expandable={record.role_id === 4 ? null : {
                                                                expandedRowKeys: expandedRowKeys3,
                                                                onExpand: handleThirdRowExpand,
                                                                expandedRowRender: (record) => (
                                                                <div className="flex flex-col gap-4 p-5 border-solid border-[#9C2552] border-2 rounded-lg">
                                                                    <span className="text-xl font-semibold text-[#9C2552]">{record.username}</span>
                                                                    <Card title="Players">
                                                                    <Table
                                                                        bordered
                                                                        className="min-h-[230px]"
                                                                        dataSource={managedPlayers4}
                                                                        columns={playerColumns}
                                                                        rowKey="user_id"
                                                                        expandable={null} 
                                                                    />
                                                                    </Card>
                                                                </div>
                                                                ),
                                                            }}
                                                        />
                                                    </Card>
                                                    {record.role_id < 4 && managedPlayers3 !== null ? (
                                                        <Card title="Players">
                                                            <Table
                                                                scroll={{
                                                                    x: 1000,
                                                                    y: "100%",
                                                                }}
                                                                bordered
                                                                dataSource={managedPlayers3}
                                                                columns={playerColumns}
                                                                rowKey="user_id"
                                                            />
                                                        </Card>
                                                    ) : null}
                                                </div>
                                                ),
                                            }}
                                        />
                                        {record.role_id < 4 && managedPlayers2?.length > 0 ? (
                                            <Card title="Players">
                                                <Table
                                                    scroll={{
                                                        x: 1000,
                                                        y: 600,
                                                    }}
                                                    bordered
                                                    className={`${managedPlayers2?.length > 0 ? 'transition-opacity duration-200 opacity-100' : 'opacity-0'} min-h-[230px]`}
                                                    dataSource={managedPlayers2}
                                                    columns={playerColumns}
                                                    rowKey="user_id"
                                                />
                                            </Card>
                                        ) : null}
                                    </Card>
                                </div>
                            ),
                        }}
                    />
                </div>
            </Card>
            {role_id < 4 && managedPlayers?.length > 0  ? (
                <Card className="mt-5" title={
                    <div className="flex justify-between items-center">
                        <span>Players</span>
                        <span>Total: {formatNumberWithCommas(playerTotalGGR)}</span>
                    </div>
                }>
                <Table
                    scroll={{
                        x: 1000,
                        y: "100%",
                    }}
                    bordered
                    loading={tableLoading}
                    className={`min-h-[230px] shadow-md rounded-md`}
                    dataSource={managedPlayers}
                    columns={playerColumns}
                    rowKey="user_id"
                />
                </Card>
            ) : null}
        </div>
    )
}

export default CommissionReport