import React from "react";

const Terms = () => {
  return (
    <div className="w-full h-full bg-primary relative">
      <div className="absolute top-[-100px] bg-terms-bg bg-center bg-cover w-full h-full"></div>
    </div>
  );
};

export default Terms;
