import { Avatar, Form, Input } from "antd";
import React, { useState, useEffect} from "react";
import john from "../../../assets/resources/images/john.png";
import { getProfileInfo, updateProfileInfo, updateProfilePicture } from "../../../services/moneywin/moneywin-api";
import { useSelector } from 'react-redux';

const Profile = () => {
  const [profilePicture, setProfilePicture] = useState(john);
  const [form] = Form.useForm();
  const [profileInfo, setProfileInfo] = useState({});
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [updateStatus, setUpdateStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitVisible, setSubmitVisible] = useState(false);
  const [refresh, setRefresh] = useState (false);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      const profile = await getProfileInfo(token)
      if (profile) {
        setProfileInfo(profile);

        form.setFieldsValue({
          givenName: profile.first_name,
          familyName: profile.last_name,
          middleName: profile.middle_name,
          email: profile.email,
        });

        setNewFirstName(profile.first_name)
        setNewLastName(profile.last_name)

      }
    }

    fetchData();

  }, [token, form, refresh]);

  const handlePictureUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setProfilePicture(e.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateProfile = async (file) => {
    const update = await updateProfilePicture(profileInfo.user_id, file);
    console.log(update);
    if(update.success){

      
    }else if(update.error){


    }else if(update.no_changes){

    }
  };

  const handleFormValuesChange = () => {
    setUpdateStatus(""); 
    setSubmitVisible(true);
  };

  const onFinish = async () => {
    const update = await updateProfileInfo(profileInfo.user_id, newFirstName, profileInfo.middle_name, newLastName, profileInfo.email);
    if(update.success){
      setUpdateStatus("success");
      setSubmitVisible(false);

      if(refresh){
        setRefresh(false)
      }else{
        setRefresh(true);
      }
      
    }else if(update.error){
      setErrorMessage(update.error)
      setUpdateStatus("error");
    }else if(update.no_changes){
      setUpdateStatus(null);
    }
  };

  const onFinishFailed = () => {};

  return (
    <div className="w-full h-full bg-primary relative ">
      <div className="w-full absolute left-0 top-0 right-0 bg-[#1D2230] h-[265px] z-[5]"></div>
      <div className="flex h-full flex-col gap-10 items-center justify-center pt-8 pb-10">
        <div className="flex h-full flex-col md:pb-20 pb-2 text-center items-center justify-center gap-5 z-[10]">
          <span className="text-2xl text-white font-medium">
            Profile Information
          </span>
          <span className="text-[#ABABAB]">
            Update your account's profile information
            <br /> and email address.
          </span>
        </div>
        <div className="bg-transparent md:bg-white rounded-[30px] border-0 text-white max-w-[1022px] relative md:p-10 md:h-full w-[90%] z-[10]">
          <div className="flex flex-col items-center gap-4 w-full md:pt-16 h-full">
            <div className="w-full flex  items-center justify-center md:absolute md:top-[-75px]">
              <label htmlFor="pictureUpload" className="relative">
                <input
                  id="pictureUpload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handlePictureUpload}
                />
               <Avatar size={170} src={profilePicture} />
              </label>
            </div>
            <div className="flex w-full flex-col gap-4 items-center justify-center">
              <span className="md:text-black text-white text-xl font-semibold">
                {(!profileInfo.first_name && !profileInfo.last_name) ? 'John Doe' : `${profileInfo.first_name || ''} ${profileInfo.last_name || ''}`}
              </span>
              <button
                type="primary"
                htmlType="submit"
                onClick={() => handleUpdateProfile(profilePicture)}
                className="w-full h-[60px] cursor-pointer text-[20px] font-medium bg-buttonYellow hover:bg-yellow-500 text-white border-0 rounded-lg md:max-w-[305px] "
              >
                Update Profile Picture
              </button>
            </div>
            <Form
              form={form}
              className="w-full"
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              onValuesChange={handleFormValuesChange}
            >
              <div className="flex flex-col md:flex-row py-10 md:gap-10 w-full">
                <div className="w-full">
                  <Form.Item
                    className="login-input"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Given Name
                      </span>
                    }
                    name="givenName"
                  >
                    <Input
                      size="large"
                      onChange={(e) => {
                        setNewFirstName(e.target.value);
                      }}
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="login-input"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Family Name
                      </span>
                    }
                    name="familyName"
                  >
                    <Input
                      size="large"
                      onChange={(e) => {
                        setNewLastName(e.target.value);
                      }}
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    className="login-input "
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Middle Name
                      </span>
                    }
                    name="middleName"
                  >
                    <Input
                      size="large"
                      onChange={(e) => {
                        profileInfo.middle_name = e.target.value;
                      }}
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="login-input "
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Email
                      </span>
                    }
                    name="email"
                  >
                    <Input
                      id="inputEmail"
                      size="large"
                      onChange={(e) => {
                        profileInfo.email = e.target.value;
                      }}
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="w-full flex items-center justify-center mb-4">
                {updateStatus === "success" && (
                  <div style={{ color: "#5cb85c" }}>Profile updated successfully!</div>
                )}
                {updateStatus === "error" && (
                  <div style={{ color: "red" }}>Failed to update profile. {errorMessage}</div>
                )}
              </div>

              {submitVisible && (
                <div className="w-full flex items-center justify-center">
                  <button
                    type="primary"
                    htmlType="submit"
                    className="w-full h-[60px] cursor-pointer text-[20px] font-medium bg-[#364671] hover:bg-blue-400 text-white border-0 rounded-lg md:max-w-[285px] duration-150"
                  >
                    Submit
                  </button>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;