import paycoolsConfig from './paycools-config';
import moneyWinConfig from '../moneywin/moneywin-config';
import { KJUR, KEYUTIL } from 'jsrsasign';
import { Buffer } from 'buffer'

const sign = (content, privateKey) => {
  try {
    const rsaKey = KEYUTIL.getKey(privateKey);

    // Check if rsaKey is a valid RSA private key
    if (!rsaKey || rsaKey.isPublic || rsaKey.type !== 'RSA') {
      throw new Error('Invalid privateKey');
    }
    // Check if rsaKey is 4096 bits
    if (rsaKey.n.bitLength() !== 4096) {
      throw new Error('Invalid privateKey');
    }

    privateKey = privateKey
        .replace('-----BEGIN PRIVATE KEY-----', '')
        .replace('-----END PRIVATE KEY-----', '')
        .replace('-----BEGIN ENCRYPTED PRIVATE KEY-----', '')
        .replace('-----END ENCRYPTED PRIVATE KEY-----', '')
        .replace('\r', '')
        .replace('\n', '')
        .trim();

    const sig = new KJUR.crypto.Signature({ alg: 'SHA1withRSA' });
    sig.init(rsaKey);
    sig.updateString(content);
    const signature = sig.sign();

    const signed = Buffer.from(signature, 'hex').toString('base64');
    return signed;
    // Perform signing using the crypto module
    
  } catch (error) {
    throw new Error('Invalid privateKey:' + error);
  }

};

export const checkAccountBalance = async (environment) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(paycoolsConfig.proxy)}&requestMethod=POST`;

    const requestBody = {
      appId: environment === 'TEST' ? process.env.REACT_APP_PAYCOOLS_UAT_APP_ID : process.env.REACT_APP_PAYCOOLS_APP_ID,
    }; 

    const signature = Object.entries(requestBody)
      .filter(([key, value]) => value !== null && value !== '')
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const signedSignature = sign(signature,  environment === 'TEST' ? paycoolsConfig.testPrivateKey : paycoolsConfig.privateKey);

    requestBody.sign = signedSignature;
    requestBody.targetUrl = "account-check-balance";
    requestBody.environment = environment;

    const requestHeaders = {
      'Content-Type': 'application/json',
    };

    const balanceResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(requestBody),
    });

    const data = await balanceResponse.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

