import React, { useState, useEffect } from "react";
import BackOffice from "../../Authenticated/Backoffice/BackOffice";

const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulating a loading delay
    setTimeout(() => {
      setIsLoaded(true);
    }, 200); // Adjust the delay time as needed
  }, []);

  return (
    <div className={`transition-opacity duration-500 ${isLoaded ? "opacity-100" : "opacity-0"}`}>
      <BackOffice />
    </div>
  );
};

export default LandingPage;