import Checkbox from "antd/lib/checkbox";
import Form from "antd/lib/form";
import Image from "antd/lib/image";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate} from "react-router-dom";
import { useState} from "react";
import React from "react";
import loginLogo from "../../../assets/resources/images/login-logo.png";
import "./login.css";
import { authenticateUser} from "../../../services/moneywin/moneywin-api";
import { message } from "antd";
import { useDispatch } from 'react-redux';
import { login } from "../../../store/slices/auth-slice";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async () => { 
    setAuthError(""); 
    setIsLoading(true);

    const userInfo = await authenticateUser(username, password);

    if (userInfo.token) {
      
      const loginPayload = {
        isAuthenticated: true,
        token: userInfo.token,
        currentUser: {
          username: userInfo.username,
          userId: userInfo.user_id,
          roleId: userInfo.role_id
        },
      };
    
      dispatch(login(loginPayload));
      scrollToTop();
      navigate("/"); 
    }else if(userInfo.timed_out || userInfo.request_failed){
        message.error({ content: "Please check your internet connection or try again later." , key: 'custom_message' });
    }else{
      setAuthError(' ');
      message.error({ content: userInfo.message, key: 'custom_message' });
    }

    setIsLoading(false); 
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  const onFinishFailed = (errorInfo) => {
    message.error({ content: "An error has occured. Please try again later." , key: 'custom_message' });
    setIsLoading(false); 
  };

  return (
    <div className="w-full h-full bg-primary md:bg-pro-bg md:bg-cover md:bg-center flex items-center justify-center md:py-[210px] p-6">
      <div className="flex flex-col justify-between h-full w-full py-5 max-w-[508px] md:justify-center">
        <div className="bg-primary md:bg-white rounded-[30px] border-0 text-white relative md:p-10 h-auto">
          <div className="flex flex-col items-center gap-4 w-full md:pt-12 h-full">
            <div className="w-[198px] md:w-[341px] md:absolute md:top-[-190px]">
              <Image width={"100%"} preview={false} src={loginLogo} />
            </div>
            <span className="font-semibold text-[18px] text-white md:text-[28px] md:text-textDarkGray pt-6">
              Sign In to Your Account
            </span>

            <Form
              className="w-full"
              layout="vertical"
              name="basic"
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                className="login-input"
                label={
                  <span className="label-style md:text-black">Username</span>
                }
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username!",
                  },
                ]}
                validateStatus={authError ? "error" : ""}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  style={{
                    height: "56px",
                    padding: "10px",
                    borderRadius: "12px",
                    fontSize: "16px",
                  }}
                  onChange={(e) => {
                    setUsername(e.target.value)
                    setAuthError("");
                  }}
                  autoComplete="username"
                />
              </Form.Item>

              <Form.Item
                className="login-input "
                label={
                  <span className="label-style md:text-black">Password</span>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
                validateStatus={authError ? "error" : ""}
              >
                <Input.Password
                  placeholder="Password"
                  clas
                  size="large"
                  style={{
                    height: "56px",
                    padding: "10px",
                    borderRadius: "12px",
                    fontSize: "16px",
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setAuthError("");
                  }}
                  autoComplete="current-password"
                />
              </Form.Item>

              <div className="flex items-center justify-between">
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox className="text-textGray">Remember me</Checkbox>
                </Form.Item>
                <Form.Item>
                  <span className="text-textGray">Forgot your password?</span>
                </Form.Item>
              </div>

              <div className="w-full flex items-center justify-center">
                <button
                  type="primary"
                  htmltype="submit"
                  className="w-full h-[55px] text-[22px] font-semibold bg-buttonYellow cursor-pointer text-white border-0 rounded-lg md:max-w-[285px]"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                    />
                  ) : (
                    "Sign In"
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
