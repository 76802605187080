let environment = localStorage.getItem('environment') || 'PROD';

const paycoolsConfig = {
    appName: process.env.REACT_APP_PAYCOOLS_APP_NAME,

    secretKey: environment === 'TEST' ? process.env.REACT_APP_PAYCOOLS_UAT_SECRET_KEY : process.env.REACT_APP_PAYCOOLS_SECRET_KEY,
    appId: environment === 'TEST' ? process.env.REACT_APP_PAYCOOLS_UAT_APP_ID : process.env.REACT_APP_PAYCOOLS_APP_ID,

    proxy: "paycools-api",


    privateKey: `-----BEGIN PRIVATE KEY-----
    MIIJQgIBADANBgkqhkiG9w0BAQEFAASCCSwwggkoAgEAAoICAQC9Ayq/Pi5/3NQk
    h8SdU/YLwq+Q8tCGlGVj2Lm5U3Gh4r3W8gbhxfuyoHxlcnGHrQWDNZNvUypiAzFv
    ZAF/J4AN2KebYxQD6O7r6S7V4pVWDS1SrPJxch+guuzf9kKz7nVgZ501+Vdx6H4S
    FU+PrjwkLQ5l0tM9QbTAMFn86oRL49pNhh+WbrJpgYjEvXGftRRPUQ+mB/HE9h7H
    SRo3HhzA8RA9KvAiCnJhb9jdq8sSSvCAEeK6xFSSkTaTy749mEUZBSERjDqNbbym
    XTjnyyBtkMee93uNhK2L7gsJxQNdkhH/lq8owW3wHi7Qd0FB5fiPLwc9ao/KGzyg
    H6H4jr6Xerngm89l9xx1pHQ4ssNZ5da8f+4VEbiHNWNYQqHjo0R8UinrTXaf9jBY
    dpouKsUW0lPVyH237hffbYBOSo1zJMDFHyiH+nKoNu4wOqBV/BirRE5TiWVKTjw9
    aJzbeGxW0dW9BWUWumTAy66I0SGghqsFTXLlvHaIZ9MfCP0khDasnpp45tW4SP92
    KZl5tpdcm4Zmm2vw5kVSqJgv9ivMjT05zUP/SDEkK/Syc5KKW0fNJKzeIfc1S2gs
    5Rn2hZVgYbn2UjEyXyI0oyn4eiSpVfCrsuGm53lRtPhvrFL3KJbbqGg6ob76ch7o
    V/xMRJNPsv5zv0DOEIOnbuXclUKUBwIDAQABAoICAEqEqx/G9jI27/SPCnc0mPa+
    BNXSxV+pteeNlHt6aHx4lnvd49SzZ65fE2wzmIrzLg8y2jOzyCvoMl9GQEjPW+8Z
    kcrzXvrDpR6hSnPM5y+L9mh4AwWzBsEpsSYDQv0JcIFXmGWlLlKcZMfgWwO8n71r
    fheq7NljxE1ZQsEx/IKnDHQwAtgeQHzUMwXN4rQigiVbQh4lV65dknjjW1CLsK5o
    3Awhl5b0I3BC1m9ewasunG8BnjAgI2+9jJTsaM6D8AoTOZxfet4ze4LjkgHg1aIc
    yHbl/3Il8v4OFY/+enszkaEA2YHp8NwQKkjKG5JNhpd85Q/TqWj3IAnJLuR98qm6
    v0nncZLdg5jv6H7uvnDC7ynyPHMnEvCG5aLcg1Vt2C2l8s89/VsbusO+Zg2vSrHw
    kas01ns+Uwh3pgnygR9p5hNe81AxHaoVcd2PqPsFqHC/PSZzlPTJtCMxEjz0bjWu
    SJN6oTA6UfVv+nONtb5B9VOZuenwMgQF/bKJWMN7hIkGMOfVTwVTGvyo/aWyMGde
    BxTgYfHeEXGOaJN/cSZS+vvDtCuWnQPHKH3QcwubxHDkl9HceAguEf+RqJ5Qyvu4
    BLeBbpnrN6ilupHKwfWXFTRMZvGnx4kVSBQrvcqI8pOkK2wUTz+SBF0IUHZC+ecm
    VIvu0TXzoeeof5LX8/1BAoIBAQD/HCxftHtipDTbK5g54WpYpqYVhKwUKVm8zw+6
    252qu5lbj8vTc3sH73Of+YMw8GgQ+wq1TcpWz6S6VMkska2h4O4QVfcW8LSpFodK
    ITSYcBwKWo6T8Vg+Ady3zqa9L7hs67IUefbJz2z25aGVTbDZHA+/5FsFVLDGn749
    LDVXBGg6QLjbh5EJT7WPEriGXY7JjVeUDaIyxX05Xmk8n5lvSuKiOmK86FNGecO7
    ifxJTssIQzcPvhbCjtA01l5GyoakprXBEpCEHv6A7FgrihgzAxknhYvtTN43nec/
    c7IdXqahGdtayv/aEuGkhs3nnL+Dey2XHK2AuISiW8tYMduRAoIBAQC9q/cGyTuO
    Zsp4nooqy3g1EV1IZ2ovIy+DfwDqKGvi9Y+yNpipdxNS0d8Peyfhl6NKCjnXEbph
    BhihO1ExxNK88I1QMB/vPW+1b1HkuH2GvNjJJFxYFPcCw5LAzW7TGm93LoxYkXMr
    wFmUEUt/+zYgQZpPicoVyuiIUJnlFpQZt584Zl9hdLBQMKLwiyqwbtDJzekqiIM2
    fQsd65WKt3Ckx7CHy7vddFjuE9xrasf70XMM+BqN/lbZmLoBIV+Ev7+mrdb8qKfS
    ZZken0h4auMBQM4JBOVE8lKqqSrWT/CAnfSPeDh7BAflyPB7Fozu7KyFFI+KeblG
    BVJzsWh9+ToXAoIBACrZkJqCtjbbXgVyTR9rvXEmjL8QidbZGzmbjgjU2qbS2++F
    N7dMrl2m2sUEJAUUXGZ9xE6vwkzSk9i4ZRIXZhLKtTIJPneb9rxwDElnSm5QZmVW
    0JwI7cl8hpZZH6inW1LwQ/ksg0dij+ropzts0Uembv7MXH2IvCigrFOaGVuEYf1m
    4aX+H15xBQ4DFvt6u4EfPJahs5zTjzUTppmINT18VpTpOayBWXt8+GOHydiGY5P/
    tj/ureQAGPjHCQ7a9cKyPdUwSRXoHteI20Z065ul6ZO52scBIlI1M6J0zHYib9qf
    fTjC4DbA9SWtfuhoa8Kp7f5z0rDMTXr38C/gMAECggEAOQaS5r9p4I6XIVzJPcSf
    B5WI7lPpYPK7PlXY+I3eogYihfpUO99JOAVRg14Lx4/H0GLysO7rknA6x38GEmiS
    06ufURSnBU77XxlxDctKMlBk/VSpTvI+sd4LKVyajW3NRZABMj/I2MakKBhrErd8
    toYC1xceYx72esWA/5eiP3ktrc9ZrftfZzy4QIoW86TdsU7vY5JXoPJHfV9vPp2Y
    iHJhaPKgMRzh//KIuuMH7XYzmRqfIUHu6s04X06hYVAlVv+9O4mZq3BQcRyCtXGt
    fpr1V1DZfbk9kYH7FyRBsunKtbf3g0Ta+wNHCjrldJBD7l8YKRIf2iWPfKlCOV3N
    UQKCAQEAuqYP16p1+G8kGuKAbdZy5YrdAdKcgZ8x49JubyCmffLYy/niD1Cqf4d6
    cAqn64EfGcD0paot8Wh/405uPiLrwMxIKzd1n+oz3e4Zq2zLheh12VtHgFfVAo18
    9Od+cRTm2satW3q35AFjX9csz2vwWXW3hf0hAhKPBpeZtNylQPaOO1reIA8mHwtK
    vqUMp3buv4tpFA/nCLq2N2FC0UlGr1yKVxUmwT3liQEueltEF3sfDzNhea0GNnL+
    mFIjDvcKiGh5WNnn47U8iYMHEGZFA9O11d+IcvLhKr51/8ZrpCgPCDda4onZnRXz
    mVHBRgrjro/xNZ/TYUPrdmC6gQZfMw==
    -----END PRIVATE KEY-----`,

    testPrivateKey: `-----BEGIN PRIVATE KEY-----
    MIIJQQIBADANBgkqhkiG9w0BAQEFAASCCSswggknAgEAAoICAQCXLY8NNoV//JSv
    UaiksXtNwu+iufL6qI8g2tdY4VudLdm2uPOScHHqVDxPCk/e4y19pEymr5+QUz6i
    xbIqvNwIl9qMzsdvveVW2hYUv8zeW/lyzNfVgtq9zkoiF7GKu36Oe5N1VZ1VaWDe
    ips4S75Wiepys34zF0uyXXDLPScOYnGKvK/iYSymFnuS/Egh0tuTt1hEis3sxqO+
    dNpql7QPqKFagoliXWa3t+Eypnu/+V5278kYN2nsj91FOsm0sZU2p49KMGNpkpBG
    GCF3EpkAzkEgjoOy6yD22p1qTEtMBHHZsTsg3PagmL5/ZZjAPp1hTncmnui2YgDi
    ZwntSpoR/L5Xr3pYJgcdZuSXf0+G8TNWqafZmNkzt2V+d9bjtVAXGVroFPAyTJcq
    4cpEC4AmrMDNoSy29TuNztCKn9JNdPQ7tZIBCZo0cBgcxXZJ3p/GK8dySmEmmHCZ
    xnqVg3AW1epRRoAmVCtraxX6tr4kQVcsjc1jjAsBDJYcCVtve9VAJ2OcNAtXIdY/
    Bu84DdMNffmuwoQDy03F37OrSgyIkjLMmsDrrY0iyj4wWiXPcWF0Dya/UzBQTgh+
    x4Qbk/Otqdt/YoW1wNptUHTl45G6HjgUW+4bI036uLbvNwdPjYS/t0p8ZxxNUkdD
    U1hr60wJqvJFudHaJd57YzjUmcvWNwIDAQABAoICAAUxC+8toFegPYO1YsltGUmD
    vtj9JV2VExbTJrJ0FfWHqo9YBwoc96LopT2NooDwykVWf0j5Uc1FOCrWbfzoiW6q
    6fCl9Tr4IxUT3jUONSN5tk8XJV5WsdsmZ2zuS8S/SMq51OGI2om680tEW61xcT+y
    WS1hNQQQi7y8JFbgBCRXt+oTLfMXPs8UiV/aonUbszpKZ8SXnUsnsyqj0EOS1bZb
    Jldf99NuvUuxckSPOEOEDv0EodV43OSPBQLb0JHc+1cDwBIrJ6IFGRR4v0V1WD3E
    pExRUi9MKQPXlBcfazW/SiDQsX6HOAz7a3QfWA4m2FHyinlc20VpuvmlBWlAHTRT
    zyL8lATE323iPYWvAR1BjNg/K/IV1mwHixGLhZU2Qj+YOM5+ZPr1L/iKElZPy9j2
    K8SyL1zGLFHSlZGa4Cs3lce4oSgNvH12faFl06JhznWzYabvQXnQqRsy0Wl3HpkD
    RMMOr8zG37h8uuIIRQX59PqgbE8EN8iQDfuP1kLGBJwY7owbJ0FnFmt9FnX310VG
    8TkYAuxQHeuE4hRu0G1DzrWP7b2j9DuMuDYb6n3/anIg2S5B5A9+8Bs4YLjIjYGM
    +8lSpeKwDxDJvnaUq8q/zIIAeam5AbD1rdRZpqUx1uBsK+jsuLSXprpvGA4rl5fC
    6kpCDqdGFf70iwZ1IwoRAoIBAQDLr/Y0mjKW3/G0kPTk3rnigCdFWEMS633u2SjS
    TLEh8bmBT8l5vN5+036RP6i4/Ut909+WY6dvgO6flQdWZ2qoNkXxEbGuLMRoXM27
    OZI2XtxPpk+auFdkBhW9CMtptBF7eLUNg6j+gruphOc9BskueR1v+EvGL6s7U+bl
    xZWKKsjm8LEcQwFOVVWpNV80WmMPWAKIIMijLMIVRQ7odPoSYy//f6YpXXkIzg2Y
    KpVwSWbEwBIDAkLAeI5bfADJKlodf4xjcxWlExMn7jQsZGn2+pxfj66FpiJsI/73
    u2XfwNYcWj9GAPSTmIsieC62MTYY3cO5m7/52kOuScPllty5AoIBAQC+ATWVSYFv
    GWOyqtHilFoBhDsD1MeeU5jw5QZX0CMUGeIxVFBzREf6SxBLNZgCKiNR4G7Ja2IE
    9MrxVQQoV745TYrvVGTeG2Fb5OhRza0YymBhSZ3LTIG0WGxXLaUvluyYJK4Ne5Vw
    z4wOcMyAoDEI7lOpFuFALkg1efEjeBIxzHDAhgA3OgrOCoak0ukgZlyazLXwLUck
    JODNOvqk+B6FupJpDtES3NfZ/TmPnST+hK/krPvQRNOiCVuheg+ukR+UCGx5iEU1
    N8wtes4F+/1qGCjRzBO5xb3YR6JjrGkz6TEfx8d890DFlamg89eDBqcmvf6bE8Ij
    aMQCgwchdjJvAoIBADlx19r//fKFGBLXqul9UylpASpSeSA14B9jiVq+HFq5ZJru
    mKDHvmxAU3q9XGVHsmm7PlyYoFkXILVK/3XD1tW38MPLPLQllgLk4XTDCNRirnmc
    Kuk0whjIior0lwGpsiCDa3RzRE77KdfmTpzsdAKyT7YLbbtoKD+kKwN0VPdRl7Vh
    /mE9GHoyIOWG0uHd+AAUzyn7LPqwHdCH2GuAyiIzbQ7+vir+XKs6CaV3pQu099HX
    ZX0Jvy+vEF8GuuYBFsco+Lv3cgxLM+TYDkjSzPvFtCJTUGIK97xN7pOJe3K+XORE
    I4IntNKHe/3trSbe/CXJLXEEVwEtKFdYIHJ4uKkCggEAPs7nh5Hk13TtIAKXnu6r
    UhUD7SBJXG0GAZuznROQhPSJcQUOEwxfPr3RhQsSVKTZSBeIh8yXekvxShVwamWW
    3Bpl8vdS4JjsdhQVN/VlYuyDL6XGI44G0jbomWjdUf3x99koMAzKOaBiC+P9tgc+
    LlWaEiseCrlB5WuJ6s+mNBAAtaOWLqthJvsTxyhxJ+Sud/1zpxFQkwIdzbnRYPck
    H9Cs1U2wjyuxrr2maXLo57m060LDevVFgCrXv2k6BdqzNZwTP5lsR0tKkz4lHJVQ
    D+IsnC15188mEniblZdIr4BGXNsNdqHFJ4txp2LzurxYRNZJc7n0UwAuyyXFFaqc
    wQKCAQBZ6Fe3OGTkA1MIQuQoscZ0zRs5ucdVFEeq2+lQyb9QRUFpJbXuzHQGDzLP
    kGjnTpNHnNRrFlqQ+/Xhsp1qJChgDhqQvkcy9ggK7+V57Ti+lOIyPBCmFaUA5v0j
    mVrNuD90ZWd/QT7Uejqd6xxjQt+TFf/010lUrLF38FnT2hsw/8HW9bxovSAPOwI8
    PO6xTV+WORFcF7DIOoDGSaHimaV+BPAPye1IrJXSyHuJkhKSxAVFZbVw8musInd4
    S3UJhENOzZQ943gB0OKUKY2hrL/jEV2gS1Mr6tfzMADkMckrnGTwCMNb+dgjdnhn
    EFYsl8DI2LxjbVKXZjsL8kKde1C7
    -----END PRIVATE KEY-----`,

  };
  
  export default paycoolsConfig;