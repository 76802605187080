import React from "react";
import "./contact.css";
import { Form, Image, Input } from "antd";
import contactTitle from "../../../assets/resources/images/contact-title.png";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const Contact = () => {
  const navigate = useNavigate();
  const onFinish = () => {};
  const onFinishFailed = () => {};
  return (
    <div className="w-full h-full bg-primary">
      <div className="bg-contact-bg bg-cover w-full h-full bg-center flex items-center justify-center flex-col gap-10  py-36 px-4">
        <div className="bg-transparent md:bg-white rounded-[30px] border-0 text-white max-w-[1022px] relative md:p-10 md:h-full w-full ">
          <div className="flex flex-col items-center gap-4 w-full md:pt-12 h-full">
            <div className="w-[198px] hidden md:block md:w-[341px] md:absolute md:top-[-60px]">
              <Image width={"100%"} preview={false} src={contactTitle} />
            </div>
            <Form
              className="w-full"
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-col md:flex-row md:gap-10 w-full">
                <div className="w-full">
                  <Form.Item
                    className="login-input"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        First name
                      </span>
                    }
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="login-input"
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Email
                      </span>
                    }
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    className="login-input "
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Last name
                      </span>
                    }
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="login-input "
                    label={
                      <span className="label-style md:text-[#6D6D6D]">
                        Contact number
                      </span>
                    }
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your number!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "60px",
                        padding: "10px",
                        fontSize: "20px",
                        borderRadius: "12px",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                className="login-input"
                label={
                  <span className="label-style md:text-[#6D6D6D]">
                    Your message
                  </span>
                }
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                <TextArea
                  style={{
                    fontSize: "20px",
                    backgroundColor: "#F2F2F2",
                  }}
                  placeholder="....."
                  rows={8}
                />
              </Form.Item>
              <div className="w-full flex items-center justify-center">
                <button
                  type="primary"
                  htmlType="submit"
                  className="w-full h-[60px] text-[20px] font-medium bg-buttonYellow hover:bg-yellow-500 text-white border-0 rounded-lg md:max-w-[285px] "
                >
                  Send message
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;