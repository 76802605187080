import { LoadingOutlined, SwapOutlined, MenuUnfoldOutlined, MenuFoldOutlined, ToolOutlined, FireOutlined } from "@ant-design/icons";
import { Avatar, Divider, Drawer, Image, Spin,Layout, Menu, Typography, Modal, Tooltip, Popover } from "antd";
import React, { useState , useEffect, useCallback } from "react";
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { logout, checkAuth } from "../../store/slices/auth-slice";
import {
  LogoutIcon,
  MenuIcon,
} from "../../assets/icons/icons";
import MONEYWINLOGO1 from '../../assets/resources/logos/MONEYWINLOGO-01.png';
import coinlogo from "../../assets/resources/images/coin-logo.png";
import email from "../../assets/resources/icons/email.png";
import footerImg from "../../assets/resources/images/footer-bg.png";
import footerImgTwo from "../../assets/resources/images/footer-bg-two.png";
import { backOffice , userPanel } from "../../static/constants";
import "./index.css";
import logoutIcon from '../../assets/resources/icons/mwIcons/logout.webp';
import { Footer } from "antd/es/layout/layout";
import { getUserInfo, getUserBalance, logoutUser} from "../../services/moneywin/moneywin-api";
import moneyWinConfig from "../../services/moneywin/moneywin-config";
import { showConstrainedMessages } from "../../services/util/utilities";
import { checkAccountBalance } from "../../services/paycools/paycools-api";
import { formatNumberWithCommas } from "../../services/util/utilities";
const { Sider, Header, Content } = Layout;

const LayoutWithRoute = ({ children }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [environment, setEnvironment] = useState(
    localStorage.getItem('environment') || moneyWinConfig.ENVIRONMENT
  );

  const [message, messageHolder] = Modal.useModal();
  const [visible, setVisible] = useState(false);

  const [loggingOut, setLoggingOut] = useState(false);
  const [switchingEnv, setSwitchingEnv] = useState(false);
  
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedKeys] = useState([location.pathname]);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [collapsed, setCollapsed] = useState(false);

  const [balance, setBalance] = useState('0.00');
  const [paycoolsBalance, setPaycoolsBalance] = useState('0.00');

  const currentUser = useSelector((state) => state.auth.currentUser);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const currentToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {  
        const getBalance = await getUserBalance(currentUser.username, 'PHP')
        if(getBalance){
          const amount = formatNumberWithCommas(getBalance.amount ?? 0.00);
          setBalance(amount);
        }
      }
    }
    fetchData();
  }, [isAuthenticated, currentUser, navigate, environment]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {  
        const getPaycoolsBalance = await checkAccountBalance(environment)
        if(getPaycoolsBalance){
          const paycoolsBalance = formatNumberWithCommas(getPaycoolsBalance.data.balance);
            setPaycoolsBalance(paycoolsBalance);
        }
      }
    }
    
    fetchData();
  }, [isAuthenticated, currentUser, navigate, environment]);

  useEffect(() => {
    if(isAuthenticated && paycoolsBalance && balance){
      setSwitchingEnv(false);
    }
  }, [isAuthenticated, paycoolsBalance, balance]);

  useEffect(() => {
    localStorage.setItem('environment', environment);
    moneyWinConfig.ENVIRONMENT = environment;
  }, [environment]);
  
  const closeDrawer = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUserInfo(currentToken);
      
      if (user.error) {
        setBalance(null);
        dispatch(logout());
      } else {
        const authenticatedPayload = {
          isAuthenticated: true,
          currentUser: {
            username: user.username,
            userId: user.user_id,
            roleId: user.role_id
          },
        };

        dispatch(checkAuth(authenticatedPayload));
      }
    };
  
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, currentToken, dispatch, navigate]);

  const navigateTo = useCallback((path) => {
    scrollToTop();
    location.pathname!== path && navigate(path, { state: { role_id: currentUser?.roleId, user_id: currentUser?.userId } });
  }, [navigate, currentUser, location.pathname]); 

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const setSelectedItem = (item) => {
    setSelectedCategory(item);
  }


  useEffect(() => {
    if(location.pathname !== "/"){

      switch(location.pathname){
        case "/backoffice":
          setSelectedItem("Dashboard");
          break;
        case "/backoffice/teammanagement":
          setSelectedItem("Team Management");
          break;
        case "/backoffice/teambetting":
           setSelectedItem("Team Betting");
          break;
        case "/backoffice/commission-report":
          setSelectedItem("Commission Report");
          break;
        case "/backoffice/transaction-records":
          setSelectedItem("Transaction Records");
          break;
        case "/backoffice/cashin-request":
          setSelectedItem("Cash-In Request");
          break;
        case "/backoffice/cashout-request":
          setSelectedItem("Cash-Out Request");
          break;
        case "/userpanel/profile":
          setSelectedItem("Profile");
          break;
        case "/userpanel/security":
          setSelectedItem("Security");
          break;
        default:
          setSelectedItem(null);
      }
    }else{
      if(location.pathname === "/"){
        setSelectedItem("Dashboard");
      }
    }
  }, [location.pathname, selectedCategory, navigateTo, messageHolder]);


  const menuClick = async (selectedItem) => {

    switch (selectedItem.key) {
      case "Dashboard":
        navigateTo("/backoffice");
        break;
      case "Team Management":
        navigateTo("/backoffice/teammanagement");
        window.scrollTo(0, 0);
        break;
      case "Team Betting":
        navigateTo("/backoffice/teambetting");
        break;
      case "Commission Report":
        navigateTo("/backoffice/commission-report");
        break;
      case "Transaction Records":
        navigateTo("/backoffice/transaction-records");
        break;
      case "Cash-Out Request":
        navigateTo("/backoffice/cashout-request");
        break;
      case "Cash-In Request":
        navigateTo("/backoffice/cashin-request");
        break;
      case "Profile":
        navigateTo("/userpanel/profile");
        break;
      case "Security":
        navigateTo("/userpanel/security");
        break;
      default:
        // Handle other cases
        break;
    }
  };

  const Logout = async () => {
    try {
      setLoggingOut(true);
      if(currentUser?.userId){
        await logoutUser(currentUser.userId);
      }
      dispatch(logout());
      setBalance(null);
      
    } catch (error) {
      console.error(error);
    } finally{
      setLoggingOut(false);
    }
  };
  
  const [isNavBackOffice, setIsNavBackOffice] = useState(false);
  const [isNavUserPanel, setIsNavUserPanel] = useState(false);

  useEffect(() => {
    if(location.pathname.startsWith('/backoffice') || location.pathname === "/"){
      setIsNavBackOffice(true);
    }
    if(location.pathname.startsWith('/userpanel')){
      setIsNavUserPanel(true);
    }
  }, [location]);

  const popoverContent = (
    <div className="flex flex-col justify-center items-center gap-3 py-1 px-2">
      {isAuthenticated && <span className="text-[16px]">{currentUser?.username}</span>}
      <button 
        className="w-[150px] gradient-button border-none rounded-md p-2 outlined-text cursor-pointer"
        onClick={Logout}
        disabled={loggingOut}
      >
        {loggingOut ? <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} /> : 'Logout'}
      </button>
    </div>
  ) 

  const AuthItems = () => {
    return (
      <div className="md:flex flex-row hidden gap-10 justify-end items-center py-6 text-white ">
        {isAuthenticated && (currentUser?.roleId === 1) && (
          <span 
          className={`cursor-pointer font-regular text-[16px] hover:text-blue-200 min-w-[90px] ${isNavBackOffice && 'text-[#EAB30F]'}`}
            onClick={() => {
              navigateTo("/");
              setSelectedCategory('Dashboard');
              setIsNavBackOffice(true);
              setIsNavUserPanel(false);
            }}
          >
            Back Office
          </span>
        )}
        <span
          className={`cursor-pointer font-regular text-[16px] hover:text-blue-200 min-w-[90px] ${isNavUserPanel && 'text-[#EAB30F]'}`}
          onClick={() => {
            window.scrollTo(0, 0);
            navigateTo("/userpanel/profile");
            setSelectedCategory('Profile');
            setIsNavBackOffice(false);
            setIsNavUserPanel(true);
          }}
        >
          User Panel
        </span>
        <Popover content={popoverContent} trigger="click">
          <Avatar
            className="bg-white text-black"
            size={42}
          >
            <span>{currentUser?.username?.slice(1) && currentUser?.username?.charAt(0).toUpperCase()}</span>
          </Avatar>
        </Popover>
      </div>
    );
  };

  const SwitchEnvironment = async () => {
    const messages = [""];
    showConstrainedMessages(message,`Switch to ${environment === 'PROD' ? 'Test' : 'Production'} Environment?`, messages, "PROCEED", 0, async () => {
      setSwitchingEnv(true);
      moneyWinConfig.ENVIRONMENT = environment === 'PROD' ? 'TEST' : 'PROD';
      setEnvironment(moneyWinConfig.ENVIRONMENT);
    });
  }

  const getItem = (label, key, children) => {
    return {
      key,
      children,
      label,
    };
  };

  const backOfficeItems = backOffice.map((obj, key) =>
  getItem(
    <div key={key} className={`flex gap-3 items-center  ${isMobile ? '' : collapsed && 'justify-center'}`}>
      <Tooltip title={collapsed && obj.title} placement="right">
        <Typography
          level={4}
          // className={`my-2 menu-item text-lg md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3`}
          className={`${isMobile ? 'my-0 menu-item' : 'my-0'} menu-item-text-selected text-[#9C9C9C] text-lg md:text-[14px] font-semibold flex items-center gap-3`}
        >
          <Image preview={false} src={obj.icon} style={{ width: 40, height: 40, filter: selectedCategory === obj.title && 'brightness(0) invert(1)' }}/>
          {/* { collapsed ? null : <span>{obj.title}</span>} */}
          { collapsed ? isMobile ? <span>{obj.title}</span> : null : <span>{obj.title}</span>}
        </Typography>
      </Tooltip>
    </div>,
    obj.title
  )
);

  const userPanelItems = userPanel.map((obj, key) =>
  getItem(
    <div key={key} className={`flex gap-3 items-center  ${isMobile ? '' : collapsed && 'justify-center'}`}>
      <Tooltip title={collapsed && obj.title} placement="right">
        <Typography
          level={4}
          // className="my-2 menu-item text-lg md:text-[14px] text-[#9C9C9C] font-semibold flex items-center gap-3"
          className={`${isMobile ? 'my-0 menu-item' : 'my-2'} menu-item-text-selected text-[#9C9C9C] text-lg md:text-[14px] font-semibold flex items-center gap-3`}
        >
          <Image preview={false} src={obj.icon} style={{ width: 40, height: 40, filter: selectedCategory === obj.title && 'brightness(0) invert(1)'}}/>
          {/* {collapsed ? null : <span>{obj.title}</span>} */}
          { collapsed ? isMobile ? <span>{obj.title}</span> : null : <span>{obj.title}</span>}
        </Typography>
      </Tooltip>
    </div>,
    obj.title
  )
  );

  return (
    <Layout style={{ minHeight: "100vh", background: "#1D2232" }}>
      <Sider
        collapsible
        collapsed={isMobile || collapsed}
        onBreakpoint={(broken) => {
          setCollapsed(true);
        }}
        style={{
          background: "#141720",
          zIndex: isMobile ? "0" : "1000",
        }}
        width={"317px"}
        className={
          location.pathname === "/login" ||
          location.pathname === "/faq" ||
          location.pathname === "/terms-and-conditions" ||
          location.pathname === "/privacy-policy" ||
          location.pathname === "/signup"
            ? "hidden"
            : "sidebar"
        }
        theme="light"
        collapsedWidth={isMobile ? 0 : undefined}
        trigger={null}
      >
        <div className="sticky left-0 top-5 bottom-0 h-screen">
          <div className={`flex flex-col ${collapsed ? 'px-3 py-4' : 'px-5 py-2'}  gap-5 h-full overflow-y-auto`}> {/* - xx */}
            <div
              // className="text-[20px] font-medium flex items-center gap-2 cursor-pointer"
              className={`text-[20px] font-medium flex ${collapsed ? 'flex-wrap justify-center' : 'justify-between'} items-center gap-y-3 w-full`} // - xx
            > 

              {/* - xx */}
              {collapsed ? (
                <MenuUnfoldOutlined
                  className="text-white text-[25px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                  onClick={() => setCollapsed(false)}
                />
              ) : (
                <>
                  <MenuFoldOutlined
                    className="text-white text-[25px] hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                    onClick={() => setCollapsed(true)}
                  />
                  <div className="flex justify-center items-center w-full py-5 text-white">
                    {environment === "TEST" ? <>DevMoney<span className="text-textYellow italic">Win</span></> : <>Money<span className="text-textYellow italic">Win</span></>}
                  </div>
                </>
              )} 
            </div>

                        

            {/* BALANCE */}
            
            <div
              className={`${
                isAuthenticated ? collapsed ? "hidden" : "flex" : "hidden" // -xx
              } flex-col gap-3 h-[Auto] bg-[#202638] rounded-md p-4`}
            >
              {balance ? (
                <div className="flex flex-col gap-1">
                  <span className="text-textYellow text-xl font-semibold">Wallet</span>
                  <div className="bg-white h-[50px] w-full flex items-center justify-center rounded-md">
                    <span className="text-lg font-semibold">{balance}</span>
                  </div>
                </div>
              ) : (
                <div className="bg-white h-[64px] w-full flex items-center justify-center rounded-md">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
              )}
              <div className="flex flex-col gap-1">
                <span className="text-textYellow text-xl font-semibold">Paycools</span>
                <div className="bg-[#3574EC] h-[50px] rounded-md flex justify-center items-center text-white w-full p-4">
                  <span className="text-lg font-semibold">₱ {paycoolsBalance}</span>
                </div>
              </div>
            </div>
            {/* RECHARGE AND BALANCE */}

            {/* MENU ITEMS */}
            
            <Menu
              theme="light"
              className="bg-[#1D2232] rounded menu-items hover:text-[#1D2232]"
              selectedKeys={selectedCategory}
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              items={
                location.pathname.startsWith("/userpanel")
                ? userPanelItems
                : backOfficeItems
            }
              onClick={menuClick} 
            ></Menu>
            {/* MENU ITEMS */}
            
            <Divider className="my-3 bg-dividerGray" />

            {/* LOGOUT BUTTON */}
            
            {isAuthenticated ?
              <div
                className={`flex bg-[#1D2232] w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${
                  !collapsed
                    ? 'justify-center py-4 px-7 gap-3'
                    : 'justify-center py-4' // Adjust padding for vertical centering
                }`}
                onClick={Logout}
              >
                {collapsed ? (
                  <Tooltip placement="right" title={<div className="m-2"> Logout </div>}>
                    {loggingOut ? 
                      <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                    : 
                    <div onClick={Logout}>
                      <Image src={logoutIcon} preview={false} style={{ width: '40px', height: '40px', color: 'white' }} />
                    </div>
                    }
                    
                  </Tooltip>
                ) : (
                  loggingOut ? (
                    <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                  ) : (
                    <>
                      <div className="flex gap-3">
                      <div className="flex gap-3 items-center" onClick={Logout}>
                        <Image src={logoutIcon} preview={false} style={{ width: '40px', height: '40px', color: 'white' }} />
                        <span className="text-[#9C9C9C] font-semibold text-lg">Logout</span>
                      </div>
                      </div>
                    </>
                  )
                )}
              </div>
            : null}

            <div
              className={`flex border-solid border-[#D75252] border-2 w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${
                !collapsed
                  ? 'justify-center py-4 px-7 gap-3'
                  : 'justify-center py-4' // Adjust padding for vertical centering
              }`}
              onClick={SwitchEnvironment}
            >
              {collapsed ? (
                <Tooltip placement="right" title={<div className="m-2"> {environment === 'PROD' ? "Switch to Dev" : "Switch to Prod"} </div>}>
                  {switchingEnv ? 
                    <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                  : 
                  <div onClick={SwitchEnvironment}>
                    <SwapOutlined style={{ color: '#D75252' }} />
                  </div>
                  }
                  
                </Tooltip>
              ) : (
                switchingEnv ? (
                  <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                ) : (
                  <>
                   <div className="flex gap-3">
                      <div className="flex gap-3 items-center">
                        {environment === 'PROD' ? (
                          <>
                          <span className="text-center text-[#D75252] text-lg">Switch to Dev</span>
                          </>
                        ) : (
                          <>
                            <span className="text-center text-[#D75252] text-lg">Switch to Prod</span>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
            {/* LOGOUT BUTTON */}

            <span className={`${collapsed && "hidden"} text-center text-[14px] pb-30 text-footerGray`}>
              © MoneyWin
            </span>
          </div>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="header bg-primary h-auto p-0 md:px-7"
          style={{
            height: "6rem",
                alignItems: "center",
                justifyContent: "end",
                position: "sticky",
                top: "0",
                zIndex: collapsed ? '1000' : '500',
                width: "100%",
          }}
        >
          <div className="flex md:hidden justify-between px-4 h-full items-center w-full">
            <div className="md:hidden text-white flex items-center">
              {collapsed ? (
                <MenuUnfoldOutlined
                  className="menu trigger "
                  style={{ fontSize: '20px', padding: '4px' }}
                  onClick={() => setVisible(true)}
                />
              ) : (
                <MenuUnfoldOutlined
                  className="menu trigger "
                  style={{ fontSize: '20px', padding: '4px' }}
                  onClick={() => setVisible(true)}
                />
              )}
            </div>
            <div
              className=" text-[20px] font-medium flex items-center gap-2"
              onClick={() => navigateTo("/login")}
            >
              <Image
                style={{
                  width: "41px",
                  objectFit: "cover",
                }}
                onClick={() => navigateTo("/")}
                preview={false}
                src={coinlogo}
              />
              <span className="text-white">
                Money<span className="text-textYellow italic">Win</span>
              </span>
            </div>
          </div>

          {/* HEADER */}

          {isAuthenticated ? <AuthItems /> :  null}

          {/* HEADER */}

          <Drawer
            closable={false}
            className={`layout bg-secondary ${!isMobile ? 'hidden' : null}`}
            width={"317px"}
            placement="left"
            onClick={() => setVisible(false)}
            onClose={closeDrawer}
            open={visible}
          >
            <div className="flex flex-col p-7 gap-5 bg-secondary h-full w-full">
              <div className=" text-[20px] justify-between font-medium flex items-center ">
                <div className="flex flex-row items-center gap-2">
                  <Image
                    style={{
                      width: "41px",
                      objectFit: "cover",
                    }}
                    preview={false}
                    src={coinlogo}
                  />
                  <span className="text-white">
                    {environment === 'PROD' ? (
                      <>
                        Money<span className="text-textYellow italic">Win</span>
                      </>
                    ) : (
                      <>
                        DevMoney<span className="text-textYellow italic">Win</span>
                      </>
                    )}
                  </span>
                </div>
                <MenuFoldOutlined
                  className="text-white hover:scale-[1.3] cursor-pointer transition-all ease-in duration-150"
                  onClick={closeDrawer}
                />
              </div>
              {isAuthenticated ? (
                <>
                  <span className="cursor-pointer text-white font-semibold text-lg"
                    onClick={() => {navigateTo("/backoffice"); closeDrawer()}}>
                    Back Office
                  </span>
                  <span className="cursor-pointer text-white font-semibold text-lg"
                    onClick={() => {navigateTo("/userpanel/profile"); closeDrawer()}}>
                    User Panel
                  </span>
                  <div className="flex flex-col gap-1">
                    <span className="text-textYellow text-lg font-semibold">Wallet</span>
                     <div className="bg-white rounded-md flex justify-center items-center w-full p-4">
                    <span className="text-lg font-semibold">{balance}</span>
                  </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-textYellow text-lg font-semibold">Paycools</span>
                    <div className="bg-[#3574EC] rounded-md flex justify-center items-center text-white w-full p-4">
                      <span className="text-lg font-semibold">₱ {paycoolsBalance}</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="cursor-pointer bg-button border-0 p-4 font-semibold rounded-md text-white text-lg"
                    onClick={() => {
                      setSelectedCategory(null);
                      navigate("/login");
                      closeDrawer()
                      window.scrollTo(0, 0);
                    }}
                  >
                    Sign in
                  </button>
                </>
              )}
              {/* RECHARGE AND BALANCE */}
              <Menu
                theme="light"
                className="bg-secondary menu-items"
                mode="inline"
                selectedKeys={selectedCategory}
                defaultSelectedKeys={selectedKeys}
                items={
                    location.pathname.startsWith("/userpanel")
                    ? userPanelItems
                    : backOfficeItems
                }
                onClick={(e) => {
                  menuClick(e);
                  closeDrawer();
                }}
              ></Menu>
              <Divider className="my-3 bg-dividerGray" />
              {/* LOGOUT BUTTON */}
              <div
                className={`flex bg-[#1D2232] w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${
                  !collapsed
                    ? 'justify-center py-4 px-7 gap-3'
                    : 'justify-center py-4' // Adjust padding for vertical centering
                }`}
                onClick={Logout}
              >
                {loggingOut ? (
                  <Spin spinning={true} indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
                ) : (
                  <>
                    <Image src={logoutIcon} preview={false} style={{ width: '40px', height: '40px', color: 'white' }} />
                    <span className="text-center text-xl text-white">Logout</span>
                  </>
                )}
              </div>

              <div
                className={`flex border-solid border-[#D75252] border-2 w-full items-center cursor-pointer rounded-md transform transition-transform hover:scale-110 ${
                  !collapsed
                    ? 'justify-center py-6 px-7 gap-3'
                    : 'justify-center py-6' // Adjust padding for vertical centering
                }`}
                onClick={SwitchEnvironment}
              >
                {switchingEnv ? (
                  <Spin spinning={true} indicator={<LoadingOutlined style={{ color: '#D75252' }} spin />} />
                ) : (
                  <>

                  {environment === 'PROD' ? (
                      <>
                      <span className="text-center text-xl text-[#D75252]">Switch to Dev</span>
                      </>
                    ) : (
                      <>
                        <span className="text-center text-xl text-[#D75252]">Switch to Prod</span>
                      </>
                    )}
                    
                  </>
                )}
              </div>
              {/* LOGOUT BUTTON */}
              <span className="text-center text-[14px] pb-20 text-footerGray">
                © MoneyWin
              </span>
            </div>
          </Drawer>
        </Header>
        <Content className="layout bg-secondary" style={{ height: "100%" }}>
          {messageHolder}
          {children}
        </Content>
        <Footer
          className="bg-primary relative "
          style={{
            textAlign: "center",
          }}
        >
          <div className="flex flex-col max-w-[1360px] w-full mx-auto ">
            <div className="flex w-full md:justify-between justify-center md:px-10 items-center logo-contact ">
              <div className="hidden md:flex flex-row items-center gap-2">
                {/* <Image
                  style={{
                    width: "41px",
                    objectFit: "cover",
                  }}
                  preview={false}
                  src={coinlogo}
                />
                <span className="text-white text-[20px]">
                  Money<span className="text-textYellow italic">Win</span>
                </span> */}
                <Image
                  style={{
                    width: "90px",
                    objectFit: "cover",
                  }}
                  preview={false}
                  // src={coinlogo}
                  src={MONEYWINLOGO1}
                />
              </div>
              <div className="flex flex-row items-center gap-3">
                <Image className="rounded-lg" preview={false} src={email} />
                <span className="text-white text-[16px]">
                  support@moneywin.bet
                </span>
              </div>
            </div>
            <Divider className="bg-footerGray" />
            <div className="flex flex-wrap gap-5 md:flex-row md:justify-evenly z-[20]">
              <div className="flex flex-col text-left text-white gap-5">
                <span className="text-textLightGray text-[16px]">Slot</span>
                <span className="text-textLightGray text-[16px]">Casino</span>
                <span className="text-textLightGray text-[16px]">Fishing</span>
                <span className="text-textLightGray text-[16px]">Poker</span>
                <span className="text-textLightGray text-[16px]">Bingo</span>
              </div>
              <div className="flex flex-col text-left text-white gap-5">
                <span className="uppercase font-semibold text-[18px] italic">
                  HELP
                </span>
                <span
                  className="text-textLightGray text-[16px] cursor-pointer z-[10] hover:text-gray-600"
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </span>
                <span className="text-textLightGray text-[16px]">
                  Terms and Conditions
                </span>
                <span
                  className="text-textLightGray text-[16px] cursor-pointer z-[10] hover:text-gray-600"
                  onClick={() => navigate("/faq")}
                >
                  FAQ
                </span>
              </div>
              <div className="flex flex-col text-left text-white gap-5">
                <span className="uppercase font-semibold text-[18px] italic">
                  ABOUT US
                </span>
                <span className="text-textLightGray text-[16px]">
                  Join now for the best <br />{" "}
                  online gaming experience!
                </span>
              </div>
            </div>
            <Divider className="bg-footerGray" />
            <span className="text-footerGray py-5">
            Copyright © 2023 MoneyWin. All rights reserved.
            </span>
          </div>
          <div className="absolute bottom-0 left-0 block z-[9]">
            <Image preview={false} src={footerImg} />
          </div>
          <div className="md:absolute md:bottom-0 md:right-16 hidden md:block z-[10]">
            <Image preview={false} src={footerImgTwo} />
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutWithRoute;