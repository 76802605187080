import { Card, Table, Button, Modal, Select, message as floatingMessage } from "antd";
import React from "react";
import { useEffect , useState} from "react";
import { getTransactionRequests, getUserInfo, updateCashoutRequest } from "../../../services/moneywin/moneywin-api";
import { showConstrainedMessages, showTimedMessage, showTimedMessageWithAction, formatNumberWithCommas, formatDate } from "../../../services/util/utilities";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/auth-slice';

const CashoutRequest = () => {
  const [message, messageHolder] = Modal.useModal();
  const [cashoutRequests, setCashoutRequests] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [bonus, setBonus] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedPercentage, setSelectedPercentage] = useState(null);
  const isMobileBreakpoint = 768;
  const isMobile = window.innerWidth <= isMobileBreakpoint;
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  
  useEffect(() => {
      const fetchData = async () => {
        const response = await getUserInfo(token);
        if (response.user_id) {
          const requests = await getTransactionRequests(response.user_id, 'cashout', 'pending');
          if (requests && requests.transaction_requests && requests.transaction_requests.length > 0) {
            setCashoutRequests(requests.transaction_requests);
            setTableLoading(false);
          }else{
            setCashoutRequests([]);
            setTableLoading(false);
          }
        }else if(response.timed_out || response.request_failed){
          floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
        }else if(response.error){
          showTimedMessageWithAction(message, "Session Expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
          async () => {
            dispatch(logout());
          });  
        }
      };

      fetchData();

      const interval = setInterval(fetchData, 10000);
    
      return () => {
        clearInterval(interval);
      };

  },);

  const handleAcceptRequest = async (record, e) => {
    const messages = ["Channel : " + record.payment_channel , "Account Name : " + record.account_name, "Account No : " + record.account_number, "Amount : " + record.amount];
    showConstrainedMessages(message,"Accept Cashout Request?", messages, "PROCEED", 5, async () => {
      
      const response = await getUserInfo(token);
      
      if (response.user_id && record.transaction_reference) {
        const accept = await updateCashoutRequest(response.user_id, record.transaction_reference, 'completed');
        console.log(accept);
        if(accept.success){
          showTimedMessage(message, "Success", "Cashout Accepted", "OK", 5, true);
        }else{
          showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
        }
      }else{
        showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
      }
    });

  };

  const handleDecline = async (record) => {
    const messages = ["Channel : " + record.payment_channel , "Account Name : " + record.account_name, "Account No : " + record.account_number, "Amount : " + record.amount];
    showConstrainedMessages(message,"Decline Cashout Request?", messages, "PROCEED", 5, async () => {
      const response = await getUserInfo(token);
      if (response.user_id && record.transaction_reference) {
        const decline = await updateCashoutRequest(response.user_id, record.transaction_reference, 'declined');
        if(decline.success){
          showTimedMessage(message, "Success", "Cashout Declined", "OK", 5, true);
        }else{
          showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
        }
      }else{
        showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
      }
    });
    
  };

  const handleConfirm = async (transaction_reference, bonus, receiver_id) => {
    const response = await getUserInfo(token);
      if (response.user_id && transaction_reference && bonus && receiver_id) {
        const decline = await updateCashoutRequest(response.user_id, transaction_reference, 'declined', bonus, receiver_id);
        if(decline.success){
          showTimedMessage(message, "Success", `Cashout Declined with ${bonus} Bonus`, "OK", 5, true);
          setVisible(false);
         }else{
          
         }
      }
  };

  const handleOpenDialog = async (record) => {
    setSelectedRecord(record);
    setVisible(true);
    setSelectedPercentage(null);
    setBonus(null);
  };
  
  const handleCloseDialog = () => {
    setVisible(false);
  };
  
  const handlePercentageChange = (value) => {
    setSelectedPercentage(parseFloat(value));
    setBonus(parseFloat(value));
  };

  const columns = [
    {
      title: "Date Request",
      dataIndex: "created_at",
      key: "created_at",
      width: 260,
      align: 'center',
      render: (text) => {
        return <span>{formatDate(text)}</span>
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: "House",
      dataIndex: "house_username",
      key: "house_username",
      render: (record) => (record == null ? "-" : record),
    },
    {
      title: "Direct Link",
      dataIndex: "direct_link_username",
      key: "direct_link_username",
      render: (record) => (record == null ? "-" : record),
    },
    {
      title: "Reference",
      dataIndex: "transaction_reference",
      key: "transaction_reference",
    },
    {
      title: "Channel",
      dataIndex: "payment_channel",
      key: "payment_channel",
    },
    {
      title: "Account No.",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (type, record) => {
        let statusColor;
        if(record.status === "pending"){
          statusColor = 'text-yellow-300';
        }
        if(record.status === "completed"){
          statusColor = 'text-green-500';
        }
        if(record.status === "failed"){
          statusColor = 'text-red-500';
        }
        if(record.status === "cancelled"){
          statusColor = 'text-gray-400';
        }
        if(record.status === "declined"){
          statusColor = 'text-gray-400';
        }
        return (
          <span className={`${statusColor} font-semibold`}>{record.status.charAt(0).toUpperCase() + type.slice(1)}</span>
        )
      },
    },
    {
      title: "Action",
      key: "accept_request",
      align: "center",
      fixed: isMobile ? undefined : 'right',
      width: "200px",
      render: (text, record) => (
        <div className="flex flex-col items-center gap-2">
          <Button
            type="primary"
            className="w-full"
            onClick={() => handleAcceptRequest(record)}
          >
            Accept
          </Button>
          <Button
            className="w-full"
            onClick={() => handleOpenDialog(record)}
          >
            Decline with Bonus
          </Button>
          <Button
            className="w-full"
            onClick={() => handleDecline(record)}
          >
            Decline
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div className="bg-primary h-full w-full p-5">
      {messageHolder}
      <Modal
          title="Bonus Amount"
          open={visible}
          onCancel={handleCloseDialog}
          footer={null}
        >
          <div className="flex flex-col">
            <Select id="percentage" defaultValue="Select Value" value={selectedPercentage} onChange={handlePercentageChange}>
              <option value={selectedRecord && selectedRecord.amount * 0.10}>10% ({selectedRecord && selectedRecord.amount * 0.10})</option>
              <option value={selectedRecord && selectedRecord.amount * 0.20}>20% ({selectedRecord && selectedRecord.amount * 0.20})</option>
              <option value={selectedRecord && selectedRecord.amount * 0.30}>30% ({selectedRecord && selectedRecord.amount * 0.30})</option>
              <option value={selectedRecord && selectedRecord.amount * 0.40}>40% ({selectedRecord && selectedRecord.amount * 0.40})</option>
              <option value={selectedRecord && selectedRecord.amount * 0.50}>50% ({selectedRecord && selectedRecord.amount * 0.50})</option>
            </Select>
            <div className="flex justify-end mt-3">
              <Button 
                type="primary" 
                style={{ backgroundColor: "black" }} 
                onClick={() => handleConfirm(selectedRecord && selectedRecord.transaction_reference, bonus, selectedRecord.user_id)}
              >
                Confirm
              </Button>
            </div>
          </div>
      </Modal>
      <Card title="Cash-Out Request (Manual)" >
        <Table 
          bordered 
          dataSource={cashoutRequests} 
          columns={columns} rowKey={"key"} 
          loading={tableLoading}
          className={`min-h-[230px] shadow-md rounded-md`}
          responsive
          scroll={{
            x: 1900,
            y: '100%',
          }}
          
          />
      </Card>
    </div>
  );
};

export default CashoutRequest;