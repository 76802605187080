import moneyWinConfig from "./moneywin-config";

const createTimeoutController = (timeout) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  return { controller, timeoutId };
};

// export const getUsers = async () => {
//     const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_USERS)}&apiKey=${moneyWinConfig.API_KEY}&requestMethod=GET`);
//     const data = await response.json();
// };

export const getUserInfo = async (token) => {
  try {
    const { controller, timeoutId } = createTimeoutController(10000); // Set a timeout of 10 seconds

    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_USER_INFO)}&requestMethod=POST`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify({ token }),
      signal: controller.signal // Pass the AbortController's signal to the fetch request
    });

    clearTimeout(timeoutId); // Clear the timeout if the request completes within the timeout duration

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    if (error.name === 'AbortError') {
      return { timed_out: 1 };
    } else {
      return { request_failed: 1 };
    }
  }
};

export const logoutUser = async (user_id) => {
  const { controller, timeoutId } = createTimeoutController(10000); // Set a timeout of 10 seconds

  try {
    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.LOGOUT_USER)}&requestMethod=POST`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify({user_id}), 
      signal: controller.signal // Pass the AbortController's signal to the fetch request
    });

    clearTimeout(timeoutId); // Clear the timeout if the request completes within the timeout duration

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    await response.json();

    return true;
    
  } catch (error) {
    if (error.name === 'AbortError') {
      return { timed_out: 1 };
    } else {
      return { request_failed: 1 };
    }
  }
};

export const getDashboardData = async (user_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_DASHBOARD_DATA)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    
    const requestBody = JSON.stringify({ user_id , from_date, to_date, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDeductionData = async (user_id, role_id, date) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_DEDUCTIONS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    
    const requestBody = JSON.stringify({ user_id, role_id, date, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();

    if(data.error){
      return null;
    }
    
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getManagedUsers = async (role_id, user_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_MANAGED_USERS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ role_id, user_id, from_date, to_date, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();

    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getManagedUsersBetting = async (role_id, user_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_MANAGED_USERS_BETTING)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ role_id, user_id, from_date, to_date, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTransactionRequests = async (user_id, transaction, status) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_TRANSACTION_REQUESTS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, transaction, status, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    return null;
  }
};

export const getTransactionRecord = async (user_id, transaction) => {

  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_TRANSACTION_RECORD)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, transaction, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const processCashinRequest = async(user_id, transaction_id, action, reference, bonus, remarks) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.PROCESS_CASHIN_REQUEST)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ user_id, transaction_id , reference , action, bonus, remarks, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
  }
};


export const generateReferral = async (role_id, user_id, generated_role_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GENERATE_REFERRAL)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ role_id, user_id, generated_role_id });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const authenticateUser = async (username, password) => {
    try {
      const { controller, timeoutId } = createTimeoutController(10000); // Set a timeout of 10 seconds
    
      const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.AUTHENTICATE_LOGIN)}&requestMethod=POST`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Key': moneyWinConfig.API_KEY,
        },
        body: JSON.stringify({
          username,
          password,
          environment: moneyWinConfig.ENVIRONMENT
        }),
        signal: controller.signal // Pass the AbortController's signal to the fetch request
      });
      
      clearTimeout(timeoutId); // Clear the timeout if the request completes within the timeout duration

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseBody = await response.text();

      const responseData = JSON.parse(responseBody);
      
      return responseData;

    } catch (error) {
      if (error.name === 'AbortError') {
        return { timed_out: 1 };
      } else {
        return { request_failed: 1 };
      }
    }
  };

  export const createUser = async (username, password, email, refferal) => {
    const requestData = {
      username: username,
      password: password,
      email: email,
      refferal: refferal
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify(requestData)
    };

    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.ADD_USER)}&requestMethod=POST`, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to create user');
    }

    let responseData;

    try {
      responseData = await response.json();
    } catch (error) {
      throw new Error('Failed to parse response from server');
    }

    if (responseData.error) {
      throw new Error(responseData.error);
    }

    return true;
};

export const getUserBalance = async (username, currency) => {
  try {
    const response = await fetch(`${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_USER_BALANCE)}&requestMethod=POST`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Key': moneyWinConfig.API_KEY
      },
      body: JSON.stringify({ username, currency, environment : moneyWinConfig.ENVIRONMENT }),
    });

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getBettingHistory = async (user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_BETTING_HISTORY)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ user_id });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const modifyPercentage = async (username, percentage) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.MODIFY_AGENT_PERCENTAGE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({username, percentage});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();

    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRemainingPercentage = async (username) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_REMAINING_PERCENTAGE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({username});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });
    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getProfileInfo = async (token) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_PROFILE_INFO)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({token});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });
    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateProfileInfo = async (user_id, first_name, middle_name, last_name, email) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_PROFILE_INFO)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, first_name, middle_name, last_name, email});
                                                   
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateProfilePicture = async (user_id, profile_picture) => {

  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_PROFILE_PICTURE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('profile_picture', profile_picture);
    
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: formData,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getChipsInfo = async (user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_CHIPS_INFO)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });
    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addChips = async (user_id, receiver_user_id, amount, type) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.ADD_CHIPS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, receiver_user_id, amount, type, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();

    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addMwCash = async (user_id, receiver_user_id, amount) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.ADD_MW_CASH)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, receiver_user_id, amount, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const cashoutRequest = async(user_id, account_name, account_number, payout_channel, amount, remarks, currency = 'PHP') => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.CASHOUT_REQUEST)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ user_id, account_name, account_number, payout_channel, amount , remarks, currency, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
  }
};

export const updateCashoutRequest = async (user_id, transaction_reference, status, bonus, receiver_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_CASHOUT_REQUEST)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, transaction_reference, status, bonus, receiver_id, environment : moneyWinConfig.ENVIRONMENT});
                                                   
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updatePassword = async (user_id, old_password, new_password) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.UPDATE_PASSWORD)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, old_password, new_password});
                                                   
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUpline = async (username, user_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_UPLINE)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({ username, user_id });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const getCommissionReport = async (user_id, role_id) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_COMMISSION_REPORT)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, role_id, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCommissionReportV2 = async (user_id, role_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_COMMISSION_REPORT_V2)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, role_id, from_date, to_date, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const resetCommissions = async (user_id, role_id, mode, from_date = null, to_date = null, password) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.RESET_COMMISSIONS)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, role_id, mode, from_date, to_date, password, environment : moneyWinConfig.ENVIRONMENT});

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getReport = async (user_id, from_date = null, to_date = null) => {
  try {
    const apiUrl = `${moneyWinConfig.API_URL}?path=${encodeURIComponent(moneyWinConfig.GET_REPORT)}&requestMethod=POST`;
    const requestHeaders = {
      'Content-Type': 'application/json',
      'Key': moneyWinConfig.API_KEY,
    };
    const requestBody = JSON.stringify({user_id, from_date, to_date, environment : moneyWinConfig.ENVIRONMENT });

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: requestHeaders,
      body: requestBody,
    });

    const data = await response.json();
    return data;
    
  } catch (error) {
    console.error(error);
    return null;
  }
};

