import { Card, Table, Modal, DatePicker, message as floatingMessage } from "antd";
import moment from 'moment';
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect , useState} from "react";
import { getManagedUsersBetting, getUserInfo } from "../../../services/moneywin/moneywin-api";
import { showTimedMessageWithAction, formatNumberWithCommas } from "../../../services/util/utilities";
import { LoadingOutlined } from "@ant-design/icons";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';

const TeamBetting = () => {

  const location = useLocation();
  const role_id = location.state?.role_id;
  const user_id = location.state?.user_id;
  const [message, messageHolder] = Modal.useModal();
  const [managedUsers, setManagedUsers] = useState([]);
  const [managedUsers2, setManagedUsers2] = useState([]);
  const [managedUsers3, setManagedUsers3] = useState([]);
  const [managedUsers4, setManagedUsers4] = useState([]);
  const [managedPlayers, setManagedPlayers] = useState([]);
  const [managedPlayers2, setManagedPlayers2] = useState([]);
  const [managedPlayers3, setManagedPlayers3] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowKeys2, setExpandedRowKeys2] = useState([]);
  const [expandedRowKeys3, setExpandedRowKeys3] = useState([]);
  const [cardTitle1, setCardTitle1] = useState(null);
  const [cardTitle2, setCardTitle2] = useState(null);
  const [cardTitle3, setCardTitle3] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [isClickDisabled, setIsClickDisabled] = useState(false);
  const [totalBetLost, setTotalBetLost] = useState('');
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const handleRowExpand = async (expanded, record) => {
    if (parseInt(record.role_id) === 5) {
      return;
    }
    let newExpandedRowKeys = [];
    let newExpandedRowKeys2 = [];
    if (expanded) {
      newExpandedRowKeys = [record.user_id];
      setExpandedRowKeys([]); 
      setExpandedRowKeys2([]); 
    }
  

    setExpandedRowKeys(newExpandedRowKeys);
    setExpandedRowKeys2(newExpandedRowKeys2);
  
    if (expanded) {
      await getManagedUsersBettingUnder1(record.role_id, record.user_id);
    }
  
    let cardTitle = "";
    if (record.role_id <= 3) {
      cardTitle = "Agents";
    } else if (record.role_id === 4) {
      cardTitle = "Players";
    }
  
    setCardTitle1(cardTitle);
  };

  const handleSecondRowExpand = async (expanded, record) => {
    if (record.role_id === 5) {
      setExpandedRowKeys2([]);
      return;
    }
  
    let newExpandedRowKeys2 = [];
    if (expanded) {
      newExpandedRowKeys2 = [record.user_id];
      setExpandedRowKeys2([]);
    }
  
    setExpandedRowKeys2(newExpandedRowKeys2);
  
    if (expanded) {
      await getManagedUsersBettingUnder2(record.role_id, record.user_id);
    }
  
    let cardTitle = "";
    if (record.role_id <= 3) {
      cardTitle = "Agents";
    } else if (record.role_id === 4) {
      cardTitle = "Players";
    }
  
    setCardTitle2(cardTitle);
  };

  const handleThirdRowExpand = async (expanded, record) => {

    if (record.role_id === 5) {
      return;
    }

    let newExpandedRowKeys3 = [];
    if (expanded) {
      newExpandedRowKeys3 = [record.user_id];
      setExpandedRowKeys3([]); 
    }
  
    setExpandedRowKeys3(newExpandedRowKeys3);
  
    if (expanded) {
      await getManagedUsersBettingUnder3(record.role_id, record.user_id);
    }

    let cardTitle = "";
    if (record.role_id === 3) {
      cardTitle = "Agents";
    } else if (record.role_id === 4) {
      cardTitle = "Players";
    }
  
    setCardTitle3(cardTitle);

  };

  const getManagedUsersBettingUnder1 = async (role_id, user_id) => {
    if(role_id && user_id){
      setManagedUsers2([]); 
      setManagedPlayers2([]);
      let usersData;

      if(fromDate && toDate){
        usersData = await getManagedUsersBetting(role_id, user_id, fromDate, toDate);
      }else{
        usersData = await getManagedUsersBetting(role_id, user_id);
      }

      if (usersData && (usersData.users.length > 0 || usersData.players.length)) {
          setManagedUsers2(usersData.users);
          setManagedPlayers2(usersData.players)
      }
    }
  }

  const getManagedUsersBettingUnder2 = async (role_id, user_id) => {
    if(role_id && user_id){
      setManagedUsers3([]); 
      setManagedPlayers3([]);
      let usersData
          
      if(fromDate && toDate){
        usersData = await getManagedUsersBetting(role_id, user_id, fromDate, toDate);
      }else{
        usersData = await getManagedUsersBetting(role_id, user_id);
      }

      

      if (usersData && (usersData.users.length > 0 || usersData.players.length > 0)) {
          setManagedUsers3(usersData.users);
          setManagedPlayers3(usersData.players);
      }
    }
  }

  const getManagedUsersBettingUnder3 = async (role_id, user_id) => {
    if(role_id && user_id){
      setManagedUsers4([]); 
      let usersData
          
      if(fromDate && toDate){
        usersData = await getManagedUsersBetting(role_id, user_id, fromDate, toDate);
      }else{
        usersData = await getManagedUsersBetting(role_id, user_id);
      }

      if (usersData && (usersData.players.length)) {
          setManagedUsers4(usersData.players);
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
        if(expandedRowKeys.length === 0 && expandedRowKeys2.length === 0){
          if(role_id && user_id){
            let usersData
            
            if(fromDate && toDate){
              usersData = await getManagedUsersBetting(role_id, user_id, fromDate, toDate);
            }else{
              usersData = await getManagedUsersBetting(role_id, user_id);
            }
            
            if (usersData && (usersData.users.length > 0 || usersData.players.length)) {
                setManagedUsers(usersData.users);
                setManagedPlayers(usersData.players);
                const totalBetLost = usersData.players.reduce((sum, player) => sum + parseFloat(player.total_lost.replace(/[^0-9.-]/g, '')), 0);
                setTotalBetLost(totalBetLost);
            }
          }else{
            const response = await getUserInfo(token);
            
            if (response.user_id && response.role_id) {
              let usersData
            
              if(fromDate && toDate){
                usersData = await getManagedUsersBetting(role_id, user_id, fromDate, toDate);
              }else{
                usersData = await getManagedUsersBetting(role_id, user_id);
              }

              if (usersData && (usersData.users.length > 0 || usersData.players.length)) {
                  setManagedUsers(usersData.users);
                  setManagedPlayers(usersData.players);
                  const totalBetLost = usersData.players.reduce((sum, player) => sum + parseFloat(player.total_lost.replace(/[^0-9.-]/g, '')), 0);
                  setTotalBetLost(totalBetLost);
                  setTableLoading(false);
              }
            }else if(response.timed_out || response.request_failed){
              floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
            }else if(response.error){
              showTimedMessageWithAction(message, "Session expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
              async () => {
                dispatch(logout());
              });  
            }
          }

          setTableLoading(false);
        }
      };

      fetchData();
      
      const interval = setInterval(fetchData, 10000);
    
      return () => {
        clearInterval(interval);
      };
  }, [fromDate, toDate, dispatch, token]);

  const handleDateRangeChange = (dates) => {
    setSelectedDuration(null);
    setExpandedRowKeys2([]);
    setExpandedRowKeys([]);
    
    if (dates && dates.length > 0) {
      const startDate = moment(dates[0].toDate()).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(dates[1].toDate()).format('YYYY-MM-DD HH:mm:ss');

      setFromDate(startDate);
      setToDate(endDate);
    }else{
      setFromDate(null);
      setToDate(null);
    }
  };

  const handleDateClick = (date) => {
    if (isClickDisabled) return; // If click is disabled, return

    setExpandedRowKeys2([]);
    setExpandedRowKeys([]);

    setSelectedDuration(selectedDuration === date ? null : date);

    if (selectedDuration === null) {
      setFromDate(null);
      setToDate(null);
    }else{

      setIsClickDisabled(true);

      let startDate = new Date(); // Current date and time
      let endDate = new Date(); // Current date and time

      // Set start date to 00:00 of today
      startDate.setHours(0, 0, 0, 0);

      switch(date){
          case "7D":
              startDate.setDate(startDate.getDate() - 6); // Start date 7 days ago
              break;
          case "30D":
              startDate.setDate(startDate.getDate() - 29); // Start date 30 days ago
              break;
          default:
              // "1D" case: startDate is already set to the beginning of today
      }

      // Formatting the dates in 'YYYY-MM-DD HH:mm:ss' format
      const formatDate = (date) => {
          return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
      };

      setFromDate(formatDate(startDate));
      setToDate(formatDate(endDate));
    }

    setTimeout(() => {
      setIsClickDisabled(false); // Enable clicks after setting the dates
    }, 1000); 
  };

  const playerCols = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Total Cashed In",
      dataIndex: "total_cashin",
      key: "total_cashin",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Cashed Out",
      dataIndex: "total_cashout",
      key: "total_cashout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Placed",
      dataIndex: "total_stake",
      key: "total_stake",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Won",
      dataIndex: "total_payout",
      key: "total_payout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Lost", 
      dataIndex: "total_lost",
      key: "total_lost",
      // render: (record) => (record <= 0 ? "-" : record),
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span className="font-bold">{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Running Balance", 
      dataIndex: "running_balance",
      key: "running_balance",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
  ];

  const userCols = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "%", 
      dataIndex: "commission_percentage",
      key: "commission_percentage",
      width: 80,
    },
    {
      title: "Total Cashed-In",
      dataIndex: "total_cashin",
      key: "total_cashin",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Cashed-Out",
      dataIndex: "total_cashout",
      key: "total_cashout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Placed",
      dataIndex: "total_stake",
      key: "total_stake",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Won",
      dataIndex: "total_payout",
      key: "total_payout",
      render: (record) => (
        <div>
          {record <= 0 ? (
            <span>-</span>
          ) : (
            <span>{formatNumberWithCommas(record)}</span>
          )}
        </div>
      ),
    },
    {
      title: "Total Bets Lost", 
      dataIndex: "total_lost",
      key: "total_lost",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
    {
      title: "Running Balance", 
      dataIndex: "running_balance",
      key: "running_balance",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
    
  ];

  return (
    <div className="bg-primary h-full w-full p-5">
      {messageHolder}
      <Card 
        className="mb-4"
        title={(
          <div className="flex flex-col gap-5 py-4 md:p-4 md:flex-row md:flex-wrap md:items-center">
            <span>Team Betting</span>
            <div className="flex gap-3">
              <span 
                 style={{
                  textAlign: "center",
                  backgroundColor: selectedDuration === "1D" ? "#3F3F3F" : "white",
                  color: selectedDuration === "1D" ? "white" : "black",
                  cursor: isClickDisabled ? "not-allowed" : "pointer",
                }}
                className={`font-semibold py-2 px-4 w-[55px] rounded-md shadow-md bg-white hover:bg-[#3F3F3F] hover:text-white cursor-pointer duration-150`}
                onClick={() => handleDateClick('1D')}
              >
                 {isClickDisabled && selectedDuration === '1D' ? <LoadingOutlined /> : '1D'}
              </span>
              <span 
                style={{
                  textAlign: "center",
                  backgroundColor: selectedDuration === "7D" ? "#3F3F3F" : "white",
                  color: selectedDuration === "7D" ? "white" : "black",
                  cursor: isClickDisabled ? "not-allowed" : "pointer",
                }}
                className={`font-semibold py-2 px-4 w-[55px] rounded-md shadow-md bg-white hover:bg-[#3F3F3F] hover:text-white cursor-pointer duration-150`}
                onClick={() => handleDateClick('7D')}
              >
                {isClickDisabled && selectedDuration === '7D' ? <LoadingOutlined /> : '7D'}
              </span>
              <span 
                style={{
                  textAlign: "center",
                  backgroundColor: selectedDuration === "30D" ? "#3F3F3F" : "white",
                  color: selectedDuration === "30D" ? "white" : "black",
                  cursor: isClickDisabled ? "not-allowed" : "pointer",
                }}
                className={`font-semibold py-2 px-3 w-[55px] rounded-md shadow-md bg-white hover:bg-[#3F3F3F] hover:text-white cursor-pointer duration-150`}
                onClick={() => handleDateClick('30D')}
              >
                {isClickDisabled && selectedDuration === '30D' ? <LoadingOutlined /> : '30D'}
              </span>
            </div>
            <DatePicker.RangePicker
              className="lg:min-w-[250px] md:w-[250px] w-full h-[40px]"
              format="YYYY-MM-DD"
              placeholder={['Date From', 'Date To']}
              onChange={handleDateRangeChange}
            />
          </div>
        )}
      >
        <Table 
          scroll={{
            x: 1500,
            y: "100%",
          }}
          bordered 
          loading={tableLoading}
          dataSource={managedUsers} 
          columns={userCols} 
          rowKey="user_id"
          className="min-h-[230px] shadow-md rounded-md" 
          expandable={role_id === 4 ? null : {
            expandedRowKeys,
            onExpand: handleRowExpand,
            expandedRowRender: (record1) => ( 
              <div className="flex flex-col gap-4 p-5 border-2 border-solid border-[#2AA364] rounded-lg">
                <span className="text-xl font-semibold text-[#2AA364]">{record1.username}</span>
                <Card title={cardTitle1}>
                <Table
                  bordered
                  dataSource={managedUsers2}
                  columns={userCols}
                  rowKey="user_id"
                  expandable={record1.role_id === 4 ? null : {
                    expandedRowKeys: expandedRowKeys2,
                    onExpand: handleSecondRowExpand,
                    expandedRowRender: (record2) => (
                      <div className="flex flex-col gap-4 p-5 border-2 border-solid border-[#305EDB] rounded-lg">
                        <span className="text-xl font-semibold text-[#305EDB]">{record2.username}</span>
                        <Card title={cardTitle2}>
                        <Table
                          dataSource={managedUsers3}
                          columns={userCols}
                          rowKey="user_id"
                          expandable={record2.role_id === 4 ? null : {
                            expandedRowKeys: expandedRowKeys3,
                            onExpand: handleThirdRowExpand,
                            expandedRowRender: (record3) => (
                              <div className="flex flex-col gap-4 p-5 border-solid border-[#9C2552] border-2 rounded-lg">
                                <span className="text-xl font-semibold text-[#9C2552]">{record3.username}</span>
                                <Card title={cardTitle3}>
                                <Table
                                  dataSource={managedUsers4}
                                  columns={playerCols}
                                  rowKey="user_id"
                                  expandable={null} 
                                />
                                </Card>
                              </div>
                            ),
                          }}
                        />
                        </Card>
                        {record2.role_id < 4 && managedPlayers3?.length > 0 ? (
                          <Card title="Players">
                            <Table
                              bordered
                              scroll={{
                                x: 1200,
                              y: 620,
                              }}
                              // bordered
                              className={`min-h-[230px] shadow-md rounded-md`}
                              dataSource={managedPlayers3}
                              columns={playerCols}
                              rowKey="user_id"
                            />
                          </Card>
                        ) : null}
                      </div>
                    ),
                  }}
                />
                </Card>
                {record1.role_id < 4 && managedPlayers2?.length > 0 ? (
                  <Card title="Players">
                    <Table
                      bordered
                      scroll={{
                        x: 1200,
                      y: 620,
                      }}
                      // bordered
                      className={`min-h-[230px] shadow-md rounded-md`}
                      dataSource={managedPlayers2}
                      columns={playerCols}
                      rowKey="user_id"
                    />
                  </Card>
                ) : null}
              </div>
            ),
          }}
        />
      </Card>
      {role_id === 1 && managedPlayers?.length > 0 ? (
        <Card title={
          <div className="flex justify-between items-center">
            <span>Players</span>
            <span>Total: {formatNumberWithCommas(totalBetLost)}</span>
          </div>
        }>
          <Table
            bordered
            scroll={{
              x: 1200,
              y: 620,
            }}
            // bordered
            className={`min-h-[230px] shadow-md rounded-md`}
            dataSource={managedPlayers}
            columns={playerCols}
            rowKey="user_id"
          />
        </Card>
      ) : null}
    </div>
  );
};

export default TeamBetting;