let environment = localStorage.getItem('environment') || 'PROD';

const moneyWinConfig = {
  API_URL: process.env.REACT_APP_MW_API_URL,
  API_KEY: process.env.REACT_APP_MW_API_KEY,
  
  get ENVIRONMENT() {
    return environment;
  },

  set ENVIRONMENT(value) {
    environment = value;
  },

  GET_USERS : "get-users",
  AUTHENTICATE_LOGIN : "authenticate-admin-login",
  GET_USER_INFO : "get-user-info",
  ADD_USER : "add-user",
  LOGOUT_USER : "logout-user",
  GET_USER_BALANCE : "get-user-balance",
  GET_DASHBOARD_DATA : "get-dashboard-data",
  GET_MANAGED_USERS : "get-managed-users",
  GET_MANAGED_USERS_BETTING : "get-managed-users-betting",
  GENERATE_REFERRAL : "generate-referral",
  CASHIN_REQUEST : "cashin-request",
  GET_TRANSACTION_REQUESTS : "get-transaction-requests",
  GET_CASHIN_RECORD : "get-cashin-record",
  PROCESS_CASHIN_REQUEST : "accept-cashin-request",
  GET_BETTING_HISTORY : "get-betting-history",
  EVO_PROXY : "evo-proxy",
  EVO_AUTHENTICATE : "evo-auth",
  GET_DEDUCTIONS: "get-deductions",
  CG_PROXY: "cg-proxy",
  MODIFY_AGENT_PERCENTAGE: "modify-agent-percentage",
  GET_REMAINING_PERCENTAGE: "get-remaining-percentage",
  GET_PROFILE_INFO: "get-profile-info",
  UPDATE_PROFILE_INFO: "update-profile-info",
  UPDATE_PROFILE_PICTURE: "update-profile-picture",
  GET_CHIPS_INFO: "get-chips-info",
  ADD_CHIPS: "add-chips",
  ADD_MW_CASH: "add-mw-cash",
  UPDATE_CASHOUT_REQUEST: "update-cashout-request",
  UPDATE_PASSWORD: "update-password",
  GET_UPLINE: "get-upline",
  GET_COMMISSION_REPORT: "get-commission-report",
  RESET_COMMISSIONS: "reset-commissions",
  GET_COMMISSION_REPORT_V2: "get-commission-report-v2",
  GET_REPORT: "get-report",
};

export default moneyWinConfig;    