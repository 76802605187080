import "antd/dist/reset.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { Collapse, initTE } from "tw-elements";
import { useEffect } from "react";
import { useSelector } from 'react-redux';

import Login from "./components/Unauthenticated/Login/Login";
import LayoutWithRoute from "./components/layout";
import Faq from "./components/Unauthenticated/Faq/Faq";
import Terms from "./components/Unauthenticated/Terms/Terms";
import PrivacyPolicy from "./components/Unauthenticated/Privacy/PrivacyPolicy";
import BackOffice from "./components/Authenticated/Backoffice/BackOffice";
import TeamManagement from "./components/Authenticated/Backoffice/TeamManagement";
import TeamBetting from "./components/Authenticated/Backoffice/TeamBetting";
import TransactionRecords from "./components/Authenticated/Backoffice/TransactionRecords";
import Contact from "./components/Authenticated/Contact/Contact";
import Profile from "./components/Authenticated/UserPanel/Profile";
import CashoutRequest from "./components/Authenticated/Backoffice/CashoutRequest";
import CashinRequest from "./components/Authenticated/Backoffice/CashinRequest";
import Security from "./components/Authenticated/UserPanel/Security";
import LandingPage from "./components/Unauthenticated/Landing/LandingPage";
import CommissionReport from "./components/Authenticated/Backoffice/CommissionReport";

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  useEffect(() => {
    initTE({ Collapse });
  }, []);
  
  
  const NotAuthenticated = () => {
    return (
      <Routes>
        <Route path={"/login"} element={<Login />}/>
        <Route path={"/faq"} element={<Faq />} />
        <Route path={"/terms-and-conditions"} element={<Terms />} />
        <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  };

  const Authenticated = () => {
    return (
      <Routes>
        <Route path={"/"} element={<LandingPage/>} />
        <Route path={"/faq"} element={<Faq />} />
        <Route path={"/terms-and-conditions"} element={<Terms />} />
        <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/userpanel/profile"} element={<Profile />} />
        <Route path={"/userpanel/security"} element={<Security />} />
        <Route path={"/backoffice"} element={<BackOffice />} />
        <Route path={"/backoffice/teammanagement"} element={<TeamManagement />}/>
        <Route path={"/backoffice/teambetting"} element={<TeamBetting />} />
        <Route path={"/backoffice/cashout-request"} element={<CashoutRequest />} />
        <Route path={"/backoffice/transaction-records"} element={<TransactionRecords />} />
        <Route path={"/backoffice/cashin-request"} element={<CashinRequest />} />
        <Route path={"/backoffice/commission-report"} element={<CommissionReport/>}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  };

  return (
    <>
      <LayoutWithRoute>
        {isAuthenticated ? <Authenticated /> : <NotAuthenticated />}
      </LayoutWithRoute>
    </>
  );
};

export default App;
