import { Card, Table, Button, Modal, DatePicker, message as floatingMessage } from "antd";
import React, { useState, useEffect } from "react";
import moment from 'moment';
import { getDashboardData, getDeductionData, getUserInfo } from "../../../services/moneywin/moneywin-api";
import { useNavigate } from "react-router-dom";
import { showTimedMessageWithAction, formatNumberWithCommas } from "../../../services/util/utilities";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/auth-slice';

const BackOffice = () => {
  const [message, messageHolder] = Modal.useModal();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [deductionsLoading, setDeductionsLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [summaryArray, setSummaryArray] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const [visible, setVisible] = useState(false);
  const [gameProviders, setGameProviders] = useState([]);
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [newPlayersVisible, setNewPlayersVisible] = useState(false);
  const [newPlayersList, setNewPlayersList] = useState([]);
  const dispatch = useDispatch();

  const handleRowExpand = async (expanded, record) => {
    setDeductionsLoading(true);
    setDeductions([]);
    setExpandedRowKeys(expanded ? [record.key] : []);
  
    const response = await getUserInfo(token);
  
    if (response.user_id && response.role_id < 3) {
      if (expanded) {
        await getDeductions(response.user_id, response.role_id, record.date);
      }
    } else {
      return;
    }
  };

  
  useEffect(() => {
  
    const fetchData = async () => {

      const response = await getUserInfo(token);

      if (response.user_id) {
        let dashboardData;

        if (fromDate && toDate) {
          dashboardData = await getDashboardData(response.user_id, fromDate, toDate);
        }else{
          dashboardData = await getDashboardData(response.user_id);
        }
        
        if (dashboardData) {
          setSummaryArray(dashboardData.summary);
        }
      }else if(response.timed_out || response.request_failed){
        floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
      }else if(response.error){
        showTimedMessageWithAction(message, "Session Expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
        async () => {
          dispatch(logout());
        });  
      }

      setDashboardLoading(false);
    };
  
    fetchData();
    
    const interval = setInterval(fetchData, 10000);
  
    return () => {
      clearInterval(interval);
    };
  }, [token, fromDate, toDate, navigate, dispatch, message]);

  const getDeductions = async (role_id, user_id, date) => {
    if (user_id && role_id) {
      setDeductions([]); 
      const userData = await getDeductionData(role_id, user_id, date);
      if (userData) {
        const deductionsData = {
          key: userData.key,
          commission: userData.commission,
          downlineCommission: userData.downlineCommission,
          netGameRev: userData.netGameRev,
          provider_amounts: userData.provider_amounts,
          gameProvider: userData.gameProvider,
        };

        setDeductions([deductionsData]);
        const gameProviders = Object.keys(userData.provider_amounts).map(provider => ({
          gameProvider: provider,
          amount: userData.provider_amounts[provider],
        }));
        setGameProviders(gameProviders);
        
      }
    }

    setDeductionsLoading(false);
  };

  const handleOpenDialog = () => {
    setVisible(true);
  };

  const handleCloseDialog = () => {
    setVisible(false);
    setNewPlayersVisible(false);
  };

  const handleDateRangeChange = (dates) => {
    setDashboardLoading(true);
    if (dates && dates.length > 0) {
      const startDate = moment(dates[0].toDate()).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(dates[1].toDate()).format('YYYY-MM-DD HH:mm:ss');

      setFromDate(startDate);
      setToDate(endDate);
    }else{
      setFromDate(null);
      setToDate(null);
    }
  };

  const deductionCols = [
    {
      title: "Game Provider",
      dataIndex: "gameProvider",
      key: "gameProvider",
      render: (text, record) => {
        const isNegative = text < 0;

        return (
          <>
            <div className="flex justify-between items-center">
              <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
                {formatNumberWithCommas(text)}
              </span>
              <Button onClick={() => handleOpenDialog(record)}>...</Button>
            </div>
          </>
        )
      },
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
    {
      title: "Downline Commission",
      dataIndex: "downlineCommission",
      key: "downlineCommission",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
    {
      title: "Net Game Revenue",
      dataIndex: "netGameRev",
      key: "netGameRev",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    }
  ];
  
  const gameProviderCols = [
    {
      title: "Game Providers",
      dataIndex: "gameProvider",
      key: "gameProvider",
    },
    {
      title: "Commission",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        const numericValue = parseFloat(text.replace(/[^0-9.-]/g, '')); 
        const isNegative = numericValue < 0;
        const formattedValue = formatNumberWithCommas(numericValue);
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {/* {formatNumberWithCommas(text)} */}
            {formattedValue}
          </span>
        );
      }
    },
  ];

  const newPlayersCols = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];
  

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (record) => <span className="font-semibold">{record}</span>,
    },
    {
      title: "Total Deposits",
      dataIndex: "totalDeposits",
      key: "totalDeposits",
      render: (text) => (<span>{formatNumberWithCommas(text)}</span>)
    },
    {
      title: "Total Valid Bets",
      dataIndex: "totalValidBets",
      key: "totalValidBets",
      render: (text) => (<span>{formatNumberWithCommas(text)}</span>)
    },
    {
      title: "Total Winning Bets",
      dataIndex: "totalWinningBets",
      key: "totalWinningBets",
      render: (text) => (<span>{formatNumberWithCommas(text)}</span>)
    },
    {
      title: "Gross Gaming Revenue",
      dataIndex: "grossGamingRevenue",
      key: "grossGamingRevenue",
      render: (text, record) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
    {
      title: 'New Players',
      dataIndex: 'newPlayers',
      key: 'newPlayers',
      render: (value, record) => (
        <span className={`${value > 0 ? 'text-blue-500 cursor-pointer' : ''}`}>
          {value}
        </span>
      ),
      onCell: (record) => ({
        onClick: () => {
          const { newPlayers, date } = record;
  
          if (newPlayers > 0) {
            switch (date) {
              case 'Today':
                const todayNewPlayers = summaryArray.find((item) => item.date === 'Today');
                setNewPlayersList(todayNewPlayers?.newPlayersList || []);
                break;
              case 'Yesterday':
                const yesterdayNewPlayers = summaryArray.find((item) => item.date === 'Yesterday');
                setNewPlayersList(yesterdayNewPlayers?.newPlayersList || []);
                break;
              case 'Last 7 Days':
                const last7DaysNewPlayers = summaryArray.find((item) => item.date === 'Last 7 Days');
                setNewPlayersList(last7DaysNewPlayers?.newPlayersList || []);
                break;
              case 'Last 30 Days':
                const last30DaysNewPlayers = summaryArray.find((item) => item.date === 'Last 30 Days');
                setNewPlayersList(last30DaysNewPlayers?.newPlayersList || []);
                break;
              default:
                // Default action or no action needed
                break;
            }
          }

          if(newPlayers){
            setNewPlayersVisible(true);
          }
        },
      }),
    },
    {
      title: "Active Players",
      dataIndex: "activePlayers",
      key: "activePlayers",
    },
  ];

  return (
    <div className="bg-primary h-full w-full p-5">
      {messageHolder}
      <div className="gap-3 flex flex-col">
        <div>
        <Card 
            title={(
              <div className="flex w-full flex-col gap-5 py-3 md:flex-row items-start md:items-center">
                <span>Dashboard</span>
                <DatePicker.RangePicker
                  className="lg:min-w-[250px] md:w-[250px] w-full h-[40px]"
                  format="YYYY-MM-DD"
                  placeholder={['Date From', 'Date To']}
                  onChange={handleDateRangeChange}
                />
              </div>
            )}>

            <Modal
              title="Game Providers"
              open={visible}
              onCancel={handleCloseDialog}
              footer={null}
            >
              <Table
                dataSource={gameProviders}
                columns={gameProviderCols}
                rowKey="gameProvider"
              />
            </Modal>

            <Modal
              title="New Players"
              open={newPlayersVisible}
              onCancel={handleCloseDialog}
              footer={null}
            >
              <Table
                dataSource={newPlayersList}
                columns={newPlayersCols}
                bordered
              />
            </Modal>

            {/* Add the modal dialog */}
            <Table
              scroll={{
                x: 1000,
                y: "100%",
              }}
              bordered
              dataSource={summaryArray}
              loading={dashboardLoading}
              className={`min-h-[230px] shadow-md rounded-md`}
              columns={columns}
              rowKey={"key"}
              expandable={{
                expandedRowKeys,
                onExpand: handleRowExpand,
                expandedRowRender: (record) => ( 
                  <div className="flex flex-col gap-4">
                    <Card title="Deductions">
                      <Table
                        bordered
                        scroll={{
                          x: 1000,
                          y: "100%",
                        }}
                        loading={deductionsLoading}
                        dataSource={deductions}
                        className={`min-h-[230px] shadow-md rounded-md`}
                        columns={deductionCols}
                        rowKey="netGameRev"
                      />
                    </Card>
                  </div>
                ),
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default BackOffice;