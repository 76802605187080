import React from "react";
import "./privacy.css";

const PrivacyPolicy = () => {
  return (
    <div className="w-full h-full bg-primary">
      <div className="bg-terms-bg bg-cover w-full h-full bg-center flex items-center justify-center flex-col gap-10 py-16 px-4">
        <span className="text-white text-[36px] font-semibold">
          Privacy Policy
        </span>
        <div className="w-full min-h-[389px] max-w-[1152px] bg-privacy p-12 text-white text-[18px] leading-8 text-justify">
          We respect our players' privacy and our first priority is to secure
          our players' data. LuckyClick will never reveal personal information
          of the players to third-parties unless we are requested by
          International Courts' ruling. We reserve the right to provide the
          players' neccessary information for basic payment service providers,
          financial institutions, insurance companies via our website to
          complete payment requests. All information provided by our players
          will be sent via safety gateway ( Data Encryption Standard SSL 128
          bit) and cached in confidential environment against external
          intrusion. All internal and external data will be stricly restricted
          and tightly monitored. LuckyClick and our partners always notify the
          players of our promotions which they may concern about via email. In
          addtion, our purpose of privacy policy is not to disclose any players'
          information to third-parties. Nobody is allowed to distribute, modify,
          copy, reissue, use LuckyClick content or replicate our server without
          the express written consent of LuckyClick.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
