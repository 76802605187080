import { Card, Input, Select, Table, Button, Modal, Spin, Switch, DatePicker, message as floatingMessage } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import React from "react";
import moment from 'moment';
import { useLocation } from "react-router-dom";
import { useEffect , useState} from "react";
import { getManagedUsers, generateReferral, modifyPercentage, getRemainingPercentage, getChipsInfo, addChips, getUserInfo, getUpline, addMwCash } from "../../../services/moneywin/moneywin-api";
import { showConstrainedMessages, showTimedMessage, showTimedMessageWithAction, formatNumberWithCommas } from "../../../services/util/utilities";
import moneyWinConfig from "../../../services/moneywin/moneywin-config";
import { logout } from '../../../store/slices/auth-slice';
import { useSelector, useDispatch } from 'react-redux';

const TeamManagement = () => {

  const location = useLocation();
  const [user_id, setUserId] = useState(location.state?.user_id);
  const [role_id, setRoleId] = useState(location.state?.role_id);

  const [options] = useState([
    { value: '1', label: 'ADMIN' },
    { value: '2', label: 'HOUSE' },
    { value: '3', label: 'SUPER AGENT' },
    { value: '4', label: 'AGENT' },
    { value: '5', label: 'PLAYER' },
  ]);

  const [managedUsers, setManagedUsers] = useState([]);
  const [managedUsers2, setManagedUsers2] = useState([]);
  const [managedUsers3, setManagedUsers3] = useState([])

  const [managedPlayers, setManagedPlayers] = useState([]);
  const [managedPlayers2, setManagedPlayers2] = useState([]);
  const [managedPlayers3, setManagedPlayers3] = useState([]);
  const [managedPlayers4, setManagedPlayers4] = useState([]);
  
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowKeys2, setExpandedRowKeys2] = useState([]);
  const [expandedRowKeys3, setExpandedRowKeys3] = useState([]);

  const [referralData, setReferralData] = useState(null);

  const [cardTitle1, setCardTitle1] = useState(null);
  const [cardTitle2, setCardTitle2] = useState(null);

  const [commissionModal, setCommissionModal] = useState(false);
  
  const [message, messageHolder] = Modal.useModal();

  const [selectedRecord, setSelectedRecord] = useState(null);

  const [chipsModal, setChipsModal] = useState(false);
  const [chipsModalTitle, setChipsModalTitle] = useState(false);
  const [chipsInfo, setChipsInfo] = useState([]);
  const [chipsAmount, setChipsAmount] = useState(null);
  
  const [MwCashAmount, setMwCashAmount] = useState(null);
  const [mwCashModal, setMwCashModal] = useState(false);

  const [agentPercentage, setAgentPercentage] = useState(null);
  const [remaingPercentage, setRemainingPercentage] = useState(null);
  const [showCommission, setShowCommission] = useState(true);
  const [playerTotalGGR, setPlayerTotalGGR] = useState('');
  
  const [hasUsers, setHasUsers] = useState(false);
  const [hasUsers2, setHasUsers2] = useState(false);

  const [hasPlayers, setHasPlayers] = useState(false);
  const [hasPlayers2, setHasPlayers2] = useState(false);
  const [hasPlayers3, setHasPlayers3] = useState(false);
  
  const [tableLoading, setTableLoading] = useState(true);

  const [searchInput, setSearchInput] = useState('');
  const [searchMode, setSearchMode] =useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [addChipsLoading, setAddChipsLoading] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    
    const fetchData = async () => {

      const response = await getUserInfo(token);
      
      if (response.user_id && response.role_id) {

        if(searchMode){
          return
        };

        setUserId(response.user_id);
        setRoleId(response.role_id);
        
        let usersData; // Use 'let' to declare the variable
          
        if (fromDate && toDate) {
            usersData = await getManagedUsers(response.role_id, response.user_id, fromDate, toDate);
        } else {
            usersData = await getManagedUsers(response.role_id, response.user_id);
        }

        if (usersData) {
            setManagedUsers(usersData.users);
            setManagedPlayers(usersData.players);
            const totalGGR = usersData.players.reduce((sum, player) => sum + parseFloat(player.commission_gained || '0'), 0);
            setPlayerTotalGGR(totalGGR);
           
        }
      }else if(response.timed_out || response.request_failed){
        floatingMessage.error({ content: "Unable to retrieve data. Please check your internet connection or try again later." , key: 'custom_message' });
      }else if(response.error){
        showTimedMessageWithAction(message, "Session expired", "Your session has expired. To continue, please log in again.", "Login", 3, true, "warning" , 
        async () => {
          dispatch(logout());
        });  
      }

      setTableLoading(false);
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => {
      clearInterval(interval);
    };
      
  }, [user_id, role_id, searchMode, fromDate, toDate]);

    const handleRowExpand = async (expanded, record) => {
      
      let newExpandedRowKeys = [];
      if (expanded) {
        newExpandedRowKeys = [record.user_id];
        setExpandedRowKeys([]); 
        setExpandedRowKeys2([]); 
      }
    
      setExpandedRowKeys(newExpandedRowKeys);
    
      if (((searchMode && (hasPlayers === false && hasUsers === false)) || !searchMode) && expanded) {
        await getUsers(record.role_id, record.user_id, setManagedUsers2, setManagedPlayers2);
      }
    
      let cardTitle = "";
      if (record.role_id < 4) {
        cardTitle = "Agents";
      } else if (record.role_id === 4) {
        cardTitle = "Players";
      }
    
    
      setCardTitle1(cardTitle);
    };
    
    const handleSecondRowExpand = async (expanded, record) => {
     
      let newExpandedRowKeys2 = [];
      if (expanded) {
        newExpandedRowKeys2 = [record.user_id];
        setExpandedRowKeys2([]); 
      }
    
      setExpandedRowKeys2(newExpandedRowKeys2);
    
      if (((searchMode && (hasPlayers2 === false && hasUsers2 === false)) || !searchMode) && expanded) {
        await getUsers(record.role_id, record.user_id, setManagedUsers3, setManagedPlayers3);
      }
    
      let cardTitle = "";
      if (record.role_id < 4) {
        cardTitle = "Agents";
      } else if (record.role_id === 4) {
        cardTitle = "Players";
      }
    
      setCardTitle2(cardTitle);
    };

    const handleThirdRowExpand = async (expanded, record) => {

      let newExpandedRowKeys3 = [];
      if (expanded) {
        newExpandedRowKeys3 = [record.user_id];
        setExpandedRowKeys3([]); 
      }
    
      setExpandedRowKeys3(newExpandedRowKeys3);
    
      if (((searchMode && (hasPlayers3 === false)) || !searchMode) && expanded) {
        await getUsers(record.role_id, record.user_id, null, setManagedPlayers4);
      }
  
    };

    const getUsers = async (role_id, user_id, setManagedUsers, setManagedPlayers) => {
      if (user_id && role_id) {
        if(setManagedUsers != null){
          setManagedUsers(null); 
        }
        
        setManagedPlayers([]);

        let usersData; // Use 'let' to declare the variable
          
        if (fromDate && toDate) {
            usersData = await getManagedUsers(role_id, user_id, fromDate, toDate);
        } else {
            usersData = await getManagedUsers(role_id, user_id);
        }
        if (usersData) {
          if(role_id === 4){
            if(setManagedUsers != null){
              setManagedUsers(usersData.players);
            }
          }else{
            if(setManagedUsers != null){
              setManagedUsers(usersData.users);
            }
            setManagedPlayers(usersData.players);
          }
        }
      }
    };

    const generateRef = async (generated) => {
      if (role_id && user_id && generated) {
          const referralData = await generateReferral(role_id, user_id, generated);
          setReferralData(referralData);
      }
  
    };

  const handleOpenCommissionDialog = async (record) => {
    if(record.role_id === 5){
      return;
    }
    const remaining = await getRemainingPercentage(record.username);
    if(remaining){
      setAgentPercentage(null);
      setRemainingPercentage(remaining)
      setSelectedRecord(record);
      setCommissionModal(true);
    }else{
      console.log("error");
    }
  };

  const handleOpenChipsDialog = async (record, type) => {
    if(record.role_id !== 2){
      return;
    }
    
    setChipsInfo([]);
    setChipsAmount(null);

    setSelectedRecord(record);
    setChipsModal(true);

    if(type === "chips"){
      setChipsModalTitle("Chips");
    }else{
      setChipsModalTitle("Promotional Chips");
    }

    const chips = await getChipsInfo(record.user_id);

    if(chips){
      setChipsInfo(chips);
    }else{
      setChipsModal(false);
    }
  };

  const handleOpenMwCashDialog = async (record) => {
    if(record.role_id !== 2){
      return;
    }
    setChipsInfo([]);
    setChipsAmount(null);
    const chips = await getChipsInfo(record.user_id);
    console.log(chips);
    if(chips){
      setChipsInfo(chips);
    }

    setSelectedRecord(record);
    setMwCashModal(true);
  };

  const handleCloseCommission = () => {
    setSelectedRecord(null);
    setCommissionModal(false);
  };

  const handleCloseChips = () => {
    setChipsModal(false);
    setSelectedRecord(null);
  };

  const handleCloseMwCashModal = () => {
    setMwCashModal(false);
    setSelectedRecord(null);
  };
  
  const handleConfirmCommission = async (username, percentage) => {
    if(username && percentage){
      if(await modifyPercentage(username, percentage)){
        setAgentPercentage("");
        handleCloseCommission();
      }
    }
  };

  const handleConfirmChips = async (selectedRecord) => {
    if (selectedRecord && selectedRecord.user_id && chipsAmount) {
      let type = chipsModalTitle === "Chips" ? "chips" : "promotional";

      const messages = ["Account Name : " + selectedRecord.username, "Amount : " + chipsAmount];

      showConstrainedMessages(message,`Add ${type} Confirmation`, messages, "PROCEED", 2, async () => {
        setAddChipsLoading(true);
  
        const response = await getUserInfo(token);

        if (response) {

          const result = await addChips(response.user_id, selectedRecord.user_id, chipsAmount, type);
  
          if(result.success){
            setChipsAmount(""); // Clear the input field
            handleCloseChips(); // Close the chips modal
            showTimedMessage(message, "Success", `${type === "chips" ? "Chips" : "Promotional Chips"} Added Successfully`, "OK", 5, true);
          }else{
            showTimedMessage(message, "Failed", result.message, "OK", 5, false);
          }

          
        }else{
          showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
        }

        setAddChipsLoading(false);
  
      });
    }
  };

  const handleMwCashConfirm = async (record) => {

    if (record && MwCashAmount) {
      const messages = ["Account Name : " + record.username, "Amount : " + MwCashAmount];
      showConstrainedMessages(message,"Topup Confirmation", messages, "PROCEED", 3, async () => {
  
        const response = await getUserInfo(token);
  
        if (response) {
  
          const result = await addMwCash(response.user_id, record.user_id, MwCashAmount,);
          console.log(result);
          if(result.success){
            setMwCashAmount(""); // Clear the input field
            handleCloseMwCashModal(); // Close the chips modal
            showTimedMessage(message, "Success", "Topup Success", "OK", 5, true);
          }else{
            showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
          }
        }else{
          showTimedMessage(message, "Failed", "An error occured, please try again later", "OK", 5, false);
        }
  
      });
    }
    
  };

  const handleAgentPercentageChange = (e) => {
    let value = parseFloat(e.target.value);
    const maxValue = remaingPercentage.total_percentage; // Set the maximum value
  
    if (isNaN(value)) {
      value = ""; // Clear the input if the value is not a valid number
    } else {
      // Enforce the maximum value
      value = Math.min(value, maxValue);
    }
  
    setAgentPercentage(value);
  };

  const handleChipsAmount = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ""); // Remove all non-numeric characters except decimal point
  
    setChipsAmount(value);
  };

  const handleMwCashAmount = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ""); // Remove all non-numeric characters except decimal point
  
    setMwCashAmount(value);
  };

  const handleSearchEnter = async () => {
    
    const username = searchInput.trim().toLowerCase(); 
    if(username !== ''){
      setTableLoading(true);
      setSearchMode(true);
      setManagedUsers(null);
      setManagedUsers2(null);
      setManagedUsers3(null);
      setManagedPlayers(null);
      setManagedPlayers2(null);
      setManagedPlayers3(null);
      setManagedPlayers4(null);
      setHasPlayers(false);
      setHasPlayers2(false);
      setHasPlayers3(false);
      setHasUsers(false);
      setHasUsers2(false);
      
      const response = await getUserInfo(token);

      if (response && response.user_id) {

        const upline = await getUpline(username, response.user_id);
        
        if(upline && upline.current_user){
          const currentUser = upline.current_user[0];

          if (upline.upline.length === 0) {
            if(currentUser.role_id === 5){
              setManagedPlayers(upline.current_user);
            }else{
              setManagedUsers(upline.current_user)
            }
          }else if (upline.upline.length === 1) {
            if(currentUser.role_id === 5){
              setManagedUsers(upline.upline);
              setManagedPlayers2(upline.current_user);
              setHasPlayers(true);
            }else{
              setManagedUsers(upline.upline);
              setManagedUsers2(upline.current_user); 
              setHasUsers(true);
            }
          }else if (upline.upline.length === 2) {
            if(currentUser.role_id === 5){
              setManagedUsers([upline.upline[0]]);
              setManagedUsers2([upline.upline[1]]);
              setManagedPlayers3(upline.current_user);
              setHasUsers(true);
              setHasPlayers2(true);
            }else if(currentUser.role_id === 4){
              setManagedUsers([upline.upline[0]]);
              setManagedUsers2([upline.upline[1]]);
              setManagedUsers3(upline.current_user);
              setHasUsers(true);
              setHasUsers2(true);
            }
            
          }else if (upline.upline.length === 3) {
              setManagedUsers([upline.upline[0]]);
              setManagedUsers2([upline.upline[1]]);
              setManagedUsers3([upline.upline[2]]);
              setManagedPlayers4(upline.current_user);
              setHasUsers(true);
              setHasUsers2(true);
              setHasPlayers3(true);
          }
        }

        setTableLoading(false);
      }
      
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value); // Update the searchInput state
    if(event.target.value === ''){
      setSearchMode(false);
    } 
  };

  const handleClearSearch = () => {
    setSearchMode(false);
    setSearchInput('');
  };

  const handleSwitchChange = (checked) => {
    setShowCommission(checked);
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length > 0) {
      const startDate = moment(dates[0].toDate()).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(dates[1].toDate()).format('YYYY-MM-DD HH:mm:ss');

      setFromDate(startDate);
      setToDate(endDate);
    }else{
      setFromDate(null);
      setToDate(null);
    }
  };


  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Percentage",
      dataIndex: "agent_percentage",
      key: "agent_percentage",
      render: (text, record) => (
        <div className="flex justify-between items-center">
          <span>{text === "0%" ? "-" : text}</span>
          <Button onClick={() => handleOpenCommissionDialog(record)}>Modify</Button>
        </div>
      ),
    },
    {
      title: "Total GGR",
      dataIndex: "total_ggr", 
      key: "total_ggr",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
    {
      title: "Commission Gained",
      dataIndex: "commission_gained", 
      key: "commission_gained",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
    {
      title: "",
      key: "accept_request",
      align: "center",
      width: "150px",
      render: (text, record) => {
        if (record.role_id === 2) {
          return (
            <div style={{ width: "120px", textAlign: "center", margin: "0 auto" }}>
              <Button 
                type="primary" 
                className="mb-2" 
                style={{ minWidth: "120px", backgroundColor: "#305EDB" }}
                onClick={() => handleOpenMwCashDialog(record)}
              >
                MW Cash
              </Button>

              <Button 
                type="primary" 
                className="mb-2" 
                style={{ minWidth: "120px", backgroundColor: "#FFC839" }}
                onClick={() => handleOpenChipsDialog(record, "chips")}
              >
                Chips
              </Button>
  
              <Button 
                style={{ minWidth: "120px" }}
                onClick={() => handleOpenChipsDialog(record, "promotional")}
              >
                Promo Chips
              </Button>
            </div>
          );
        }
        return null; // If role_id is not 2, return null to render nothing.
      },
    },
  ];

  const personalDetailColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (record) => (record ? record : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },

  ];

  const playerColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Total GGR",
      dataIndex: "commission_gained",
      key: "commission_gained",
      render: (text) => {
        const isNegative = text < 0;
        return (
          <span className={`${isNegative ? 'text-red-500' : 'text-green-500'} font-semibold`}>
            {formatNumberWithCommas(text)}
          </span>
        );
      }
    },
  ];

  const playerDetailColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    { 
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (record) => (record ? record : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
  ];

  const handleGenerateReferral = (value, option) => {
    setReferralData(null);
    generateRef(value);
  };

  return (
    <div className="bg-primary h-full w-full p-5">
      <Card
        title={
          <div className="flex flex-col">
            <span className="py-5">
              Team Management 
              <Switch 
                className="ml-3" 
                checkedChildren="Commissions" 
                unCheckedChildren="Personal Details" 
                defaultChecked 
                onChange={handleSwitchChange}/>    
            </span>
            <div className="flex flex-col lg:flex-row lg:justify-between w-full py-3 gap-4 lg:items-center">
              <div className="flex gap-4">
                <Input.Search
                  id="searchInput"
                  size="large"
                  enterButton={
                    <button
                      className="border-none h-[40px] px-4 text-white rounded-lg bg-black text-sm font-semibold cursor-pointer hover:bg-[#2f2936] transition-all ease-in duration-150"
                      style={{ color: "white", borderRadius: "0 5px 5px 0" }}
                      onClick={handleSearchEnter}
                    >
                      <SearchOutlined style={{ color: "white" }} />
                    </button>
                  }
                  suffix={
                    searchMode ? (
                      <button
                        className="border-none h-[20px] w-[20px] rounded-full bg-red-500 text-white cursor-pointer ml-2 flex items-center justify-center"
                        style={{ color: "white" }}
                        onClick={handleClearSearch}
                      >
                        <CloseOutlined style={{ fontSize: "10px" }} />
                      </button>
                    ) : null
                  }
                  onChange={handleSearchInputChange}
                  value={searchInput}
                  onPressEnter={handleSearchEnter}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      handleClearSearch();
                    }
                  }}
                />

                <DatePicker.RangePicker
                  className="lg:min-w-[200px] h-[40px]"
                  format="YYYY-MM-DD"
                  placeholder={['Date From', 'Date To']}
                  onChange={handleDateRangeChange}
                />
              </div>
              <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
                <Input
                  id="referralInput"
                  size="large"
                  value={referralData ? `${moneyWinConfig.ENVIRONMENT === "TEST" ? "dev.moneywin.bet" : "moneywin.bet"}/signup?ref=${referralData.referralCode}` : ''}
                />
                <Select
                  size="large"
                  defaultValue="GENERATE REFERRAL"
                  options={options}
                  onSelect={handleGenerateReferral}
                />
              </div>
            </div>
          </div>
        }

        className="mb-4"
      >
        {/* Add the modal dialog */}
        {messageHolder}
        <Modal
          title="Modify Commission Percentage"
          open={commissionModal}
          onCancel={handleCloseCommission}
          footer={null}
          onOpen={() => {
            setAgentPercentage(""); // Clear the input field on open
          }}
        >
          <div className="flex flex-col">
            <label className="pb-2">Distributable: {remaingPercentage && remaingPercentage.total_percentage + "%"}</label>
            <label className="pb-2">Given: {selectedRecord && selectedRecord.agent_percentage}</label>
            <label className="pb-5">Remaining: {remaingPercentage && remaingPercentage.remaining_percentage + "%"}</label>
            <label className="pb-2">Username: {selectedRecord && selectedRecord.username}</label>
            <Input
              className="py-3 mb-2"
              onChange={handleAgentPercentageChange}
              value={agentPercentage}
            />
            <div className="flex justify-end">
              <Button type="primary" style={{ backgroundColor: "black" }} onClick={() => handleConfirmCommission(selectedRecord && selectedRecord.username, agentPercentage)}>Confirm</Button>
            </div>
          </div>
        </Modal>
        <Modal
          title={`Give ${chipsModalTitle}`}
          open={chipsModal}
          onCancel={handleCloseChips}
          footer={null}
          onOpen={() => {
            setChipsAmount(""); // Clear the input field on open
          }}
        >
          <div className="flex flex-col">
            {addChipsLoading ? (
              <div className="flex items-center justify-center w-full" style={{ minHeight: '200px', minWidth: '200px'}}>
                <Spin />
              </div>
            ) : (
              <>
                {chipsModalTitle === "Chips" ? (
                  <>
                    <label className="pb-2">Given {chipsModalTitle}: {chipsInfo && formatNumberWithCommas(chipsInfo.total_chips)}</label>
                    <label className="pb-2">Remaining {chipsModalTitle}: {chipsInfo && formatNumberWithCommas(chipsInfo.remaining_chips)}</label>
                  </>
                ) : (
                  <>
                    <label className="pb-2">Given {chipsModalTitle}: {chipsInfo && formatNumberWithCommas(chipsInfo.total_promo)}</label>
                    <label className="pb-2">Remaining {chipsModalTitle}: {chipsInfo && formatNumberWithCommas(chipsInfo.remaining_promo)}</label>
                  </>
                )}
                <Input
                  className="py-3 mb-2"
                  onChange={handleChipsAmount}
                  value={chipsAmount}
                />
                <div className="flex justify-end">
                  <Button 
                    type="primary" 
                    style={{ backgroundColor: "black" }} 
                    onClick={() => handleConfirmChips(selectedRecord)}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
        <Modal
          title={`Topup MW Cash`}
          open={mwCashModal}
          onCancel={handleCloseMwCashModal}
          footer={null}
          onOpen={() => {
            setMwCashAmount(""); // Clear the input field on open
          }}
        >
          <div className="flex flex-col">

            <label className="pb-2">Remaining MW Cash: {chipsInfo && formatNumberWithCommas(chipsInfo.total_mw_cash)}</label>

            <Input
              className="py-3 mb-2"
              onChange={handleMwCashAmount}
              value={MwCashAmount}
            />
            <div className="flex justify-end">
              <Button 
                type="primary" 
                style={{ backgroundColor: "black" }} 
                onClick={() => handleMwCashConfirm(selectedRecord)}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
        <div className="p-1 rounded-lg">
          <Table
            scroll={{
              x: 1000,
              y: "100%",
            }}
            bordered
            dataSource={managedUsers}
            loading={tableLoading}
            className={`min-h-[230px] shadow-md rounded-md`}
            columns={role_id === 4 ? (showCommission ? playerColumns : playerDetailColumns) : (showCommission ? columns : personalDetailColumns)}
            rowKey="user_id"
            expandable={role_id === 4 ? null : {
              expandedRowKeys,
              onExpand: handleRowExpand,
              expandedRowRender: (record) => ( 
                <div className="flex flex-col gap-4 p-5 border-2 border-solid border-[#2AA364] rounded-lg">
                  <span className="text-xl font-semibold text-[#2AA364]">{record.username}</span>
                  <Card title={cardTitle1}>
                  <Table
                    bordered
                    className="mb-4 min-h-[230px]"
                    dataSource={managedUsers2}
                    columns={record.role_id === 4 ? (showCommission ? playerColumns : playerDetailColumns) : (showCommission ? columns : personalDetailColumns)}
                    rowKey="user_id"
                    expandable={record.role_id === 4 ? null : {
                      expandedRowKeys: expandedRowKeys2,
                      onExpand: handleSecondRowExpand,
                      expandedRowRender: (record) => (
                        <div className="flex flex-col gap-4 p-5 border-solid border-[#305EDB] border-2 rounded-lg">
                          <span className="text-xl font-semibold text-[#305EDB]">{record.username}</span>
                          <Card title={cardTitle2}>
                          <Table
                            bordered
                            className="mb-4"
                            dataSource={managedUsers3}
                            columns={record.role_id === 4 ? (showCommission ? playerColumns : playerDetailColumns) : (showCommission ? columns : personalDetailColumns)}
                            rowKey="user_id"
                            expandable={record.role_id === 4 ? null : {
                              expandedRowKeys: expandedRowKeys3,
                              onExpand: handleThirdRowExpand,
                              expandedRowRender: (record) => (
                                <div className="flex flex-col gap-4 p-5 border-solid border-[#9C2552] border-2 rounded-lg">
                                  <span className="text-xl font-semibold text-[#9C2552]">{record.username}</span>
                                  <Card title="Players">
                                  <Table
                                    bordered
                                    className="min-h-[230px]"
                                    dataSource={managedPlayers4}
                                    columns={showCommission ? playerColumns : playerDetailColumns}
                                    rowKey="user_id"
                                    expandable={null} 
                                  />
                                  </Card>
                                </div>
                              ),
                            }}
                          />
                          </Card>
                          {record.role_id < 4 && managedPlayers3 !== null ? (
                          <Card title="Players">
                            <Table
                              scroll={{
                                x: 1000,
                                y: "100%",
                              }}
                              bordered
                              dataSource={managedPlayers3}
                              columns={showCommission ? playerColumns : playerDetailColumns}
                              rowKey="user_id"
                            />
                          </Card>
                        ) : null}
                        </div>
                      ),
                    }}
                  />
                {record.role_id < 4 && managedPlayers2?.length > 0 ? (
                    <Card title="Players">
                      <Table
                        scroll={{
                          x: 1000,
                          y: 600,
                        }}
                        bordered
                        className={`${managedPlayers2?.length > 0 ? 'transition-opacity duration-200 opacity-100' : 'opacity-0'} min-h-[230px]`}
                        dataSource={managedPlayers2}
                        columns={showCommission ? playerColumns : playerDetailColumns}
                        rowKey="user_id"
                      />
                    </Card>
                  ) : null}
                  </Card>
                </div>
              ),
            }}
          />
        </div>
      </Card>
      {role_id < 4 && managedPlayers?.length > 0  ? (
        <Card title={
          <div className="flex justify-between items-center">
            <span>Players</span>
            <span>Total: {formatNumberWithCommas(playerTotalGGR)}</span>
          </div>
        }>
          <Table
            scroll={{
              x: 1000,
              y: "100%",
            }}
            bordered
            loading={tableLoading}
            className={`min-h-[230px] shadow-md rounded-md`}
            dataSource={managedPlayers}
            columns={showCommission ? playerColumns : playerDetailColumns}
            rowKey="user_id"
          />
        </Card>
      ) : null}
    </div>
  );
};

export default TeamManagement;